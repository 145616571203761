import { get as bvGet, set as bvSet, remove as bvRemove } from 'bv-local-storage';
import { locale } from 'bv-i18n';
import { v as bvVar } from 'bv';
import { omit } from 'underscore';

const brandId = bvVar('brandId');

const KEY = 'registrationFormV2';
const VALIDATED_FIELDS_KEY = 'validatedFields';

const userData = {
  screenResolution: `${window.innerWidth}x${window.innerHeight}`,
  screenOrienation: window.screen.height > window.screen.width ? 'portrait' : 'landscape',
  osLanguage: navigator.language,
};

const savedDataExpired = (timestamp) => {
  if (!timestamp) return true;
  const currentTimestamp = new Date();
  const storedTimestamp = new Date(timestamp);

  return (currentTimestamp - storedTimestamp) > (24 * 60 * 60 * 1000);
};

const getFormValues = () => {
  const storedData = bvGet(KEY) || {};
  const currentRegData = storedData[`${brandId}-${locale()}`] || {};
  if (savedDataExpired(currentRegData.timestamp)) return { userData };
  return { userData, ...currentRegData };
};

const getValidatedFields = (registrationId) => {
  const storedData = bvGet(KEY) || {};
  const validatedFields = storedData[VALIDATED_FIELDS_KEY];

  if (!validatedFields || validatedFields.registrationId !== registrationId) return {};
  return validatedFields;
};

const setFormValues = (values, formState) => {
  const errors = Object.keys(formState.errors || {});
  const storedData = bvGet(KEY) || {};
  storedData[`${brandId}-${locale()}`] = {
    ...omit(values, ...errors),
    timestamp: new Date().toISOString(),
  };
  bvSet(KEY, storedData);
};

const setValidatedFields = (registrationId, action) => {
  const { field, value, result } = action;

  const storedData = bvGet(KEY) || {};
  const validatedFields = storedData[VALIDATED_FIELDS_KEY];

  if (!validatedFields || validatedFields.registrationId !== registrationId) {
    bvSet(KEY, {
      ...storedData,
      [VALIDATED_FIELDS_KEY]: {
        registrationId,
        [field]: { [value]: result },
      },
    });
  } else {
    const updatedData = { ...validatedFields };
    if (!(field in updatedData)) updatedData[field] = {};
    updatedData[field][value] = result;
    bvSet(KEY, { ...storedData, [VALIDATED_FIELDS_KEY]: updatedData });
  }
};

const removeFormValues = () => bvRemove(KEY);

export {
  setFormValues,
  getFormValues,
  removeFormValues,
  getValidatedFields,
  setValidatedFields,
};
