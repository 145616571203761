import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { Form } from 'react-final-form';
import withRegModal from 'RegistrationV2/hocs/with_reg_modal';

import { LoadingOverlay } from 'RegistrationV2/common/components/index';
import FormStep from './form_step/form_step_container';

const RegistrationView = ({
  activeSteps, currentStep, showLoadingOverlay, isLastStep, initialValues, onSubmit,
}) => (
  <>
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{
        triggerValidation(fieldsToValidate, state, { changeValue }) {
          fieldsToValidate.forEach((fieldName) => {
            changeValue(state, fieldName, (n) => n);
          });
        },
      }}
      initialValuesEqual={isEqual}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <FormStep
          isFirstStep={currentStep.number === 1}
          isLastStep={isLastStep}
          activeSteps={activeSteps}
          currentStep={currentStep}
          handleSubmit={handleSubmit}
        />
      )}
    />
    {showLoadingOverlay && <LoadingOverlay />}
  </>
);

RegistrationView.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  currentStep: PropTypes.instanceOf(Object).isRequired,
  activeSteps: PropTypes.instanceOf(Array).isRequired,
  showLoadingOverlay: PropTypes.bool.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withRegModal('sign_up')(RegistrationView);
