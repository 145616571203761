const PromotionsContainer = () => (
  <div className="regv2__right-content--promotion">
    <p>
      Promotion Images Placeholder
      <br />
      (Fixed and adapts to full screen height)
    </p>
  </div>
);

export default PromotionsContainer;
