import { t } from 'bv-i18n';

export default ({ errorMessage, value }) => (input) => {
  const error = t(`${errorMessage}`, { value });
  if (typeof input === 'string') {
    const birthDate = new Date(input);
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - birthDate;
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears <= value ? undefined : error;
  }
  return error;
};
