import PropTypes from 'prop-types';
import classnames from 'classnames';

const Step = ({
  step, currentStep, onStepClick,
}) => {
  const isCurrentStep = step.number === currentStep.number;

  const stepClickHandler = () => onStepClick(step);

  const stepClassNames = () => classnames('regv2-steps-indicator__step', {
    active: isCurrentStep,
    finished: step.number < currentStep.number,
  });

  return (
    <span className={stepClassNames()} onClick={stepClickHandler} />
  );
};

Step.propTypes = {
  step: PropTypes.instanceOf(Object).isRequired,
  currentStep: PropTypes.instanceOf(Object).isRequired,
  onStepClick: PropTypes.func.isRequired,
};

export default Step;
