import { requireScript } from 'bv';
import { guard } from 'bv-services/features';

const featureFlipperDependencies = [{
  flipper: 'iovation',
  script: window.JSBundles.IovationLoader,
}];

export const loadFeatureFlipperDependencies = () => (
  featureFlipperDependencies.map((feature) => (
    guard(feature.flipper, () => requireScript(feature.script))
  ))
);
