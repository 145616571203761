import PropTypes from 'prop-types';
import FormField from './form_field';

const FormFieldRow = ({ row }) => {
  const { fields } = row;

  if (!fields.length) return null;

  if (fields.length === 1) {
    return (
      <div className="regv2-field-wrapper regv2-field-wrapper-full-width">
        <FormField
          key={fields[0].name}
          {...fields[0]}
        />
      </div>
    );
  }

  return (
    <div className="regv2-field-wrapper regv2-field-wrapper-half-width">
      {fields.map((field) => (
        <FormField
          key={field}
          {...field}
        />
      ))}
    </div>
  );
};

FormFieldRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};

export default FormFieldRow;
