import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { OnOff } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';
import { FieldError } from 'RegistrationV2/common/components';
import { getDepositLimitOptions, getCurrencies, fetchDepositLimitOptions } from 'RegistrationV2/ducks';

const DepositLimit = ({ input, meta: { touched, error } }) => {
  const [useExisting, setUseExisting] = useState(false);
  const dispatch = useDispatch();
  const { options, defaultValue } = useSelector(getDepositLimitOptions);
  const currencies = useSelector(getCurrencies);
  const { values: { countryIsoCode, currencyIsoCode } } = useFormState();

  // Reset value when destroyed
  useEffect(() => () => input.onChange(null), []);

  useEffect(() => {
    dispatch(fetchDepositLimitOptions(countryIsoCode, currencyIsoCode || currencies[0]));
  }, [countryIsoCode]);

  useEffect(() => {
    if (options.includes(defaultValue)) {
      input.onChange(useExisting ? '' : defaultValue);
    }
  }, [options, defaultValue, useExisting]);

  if (!options.length) return null;

  return (
    <div className="regpath__rdl-container reg-path__title-own-card">
      <h4 className="reg-path__title-own-card">
        {t('account_form.monthly_deposit_limit.title')}
      </h4>
      <p className="account-form__msg">
        {t('account_form.monthly_deposit_limit.description')}
      </p>
      <div className="regv2-form-group">
        <select
          className="regv2-form-control"
          key={input.name}
          value={input.value}
          disabled={useExisting}
          {...input}
        >
          <option value="" disabled>
            {useExisting
              ? toCurrency(defaultValue, { currency: currencyIsoCode, decimalPlaces: 0 })
              : t('deposit_limit.form.hint')}
          </option>
          {options.map((option) => (
            <option key={option} value={option}>
              {toCurrency(option, { currency: currencyIsoCode, decimalPlaces: 0 })}
            </option>
          ))}
        </select>
      </div>
      <div className="account-form__toggle">
        <span>
          {t('account_form.monthly_deposit_limit.use_existing')}
        </span>
        <OnOff
          className="small"
          onClick={() => setUseExisting((state) => !state)}
          checked={useExisting}
        />
      </div>
      <FieldError showError={touched} error={error} />
    </div>
  );
};

DepositLimit.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default DepositLimit;
