import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { parseIncompletePhoneNumber } from 'libphonenumber-js';
import { getInitCountry, getExamplePhoneNumber } from 'RegistrationV2/helpers/phone_number';
import { getPhoneNumberCountries, getCurrentCountry } from 'RegistrationV2/ducks';
import PhoneNumberView from './phone_number_view';

const PhoneNumberContainer = ({
  input, label, hint, meta, hasAsyncValidation,
}) => {
  const countryOptions = useSelector(getPhoneNumberCountries);
  const currentCountry = useSelector(getCurrentCountry);

  const form = useForm();
  const [isAutofill, setIsAutofill] = useState(false);
  const [currentPhoneCountry, setCurrentPhoneCountry] = useState(
    getInitCountry(input.value, currentCountry, countryOptions),
  );

  const [placeholder, setPlaceholder] = useState(
    getExamplePhoneNumber(currentPhoneCountry.code) || t(`registration.field.${input.name}.placeholder`),
  );

  useEffect(() => {
    setPlaceholder(
      getExamplePhoneNumber(currentPhoneCountry.code) || t(`registration.field.${input.name}.placeholder`),
    );
  }, [currentPhoneCountry]);

  useEffect(() => {
    if (!input.value) {
      input.onChange({
        country_iso: currentPhoneCountry.code,
        dial_code: currentPhoneCountry.dialCode,
      });
    }
  }, []);

  useEffect(() => {
    if (input.value && input.value.dial_code
      && input.value.dial_code !== currentPhoneCountry.dialCode
    ) {
      setCurrentPhoneCountry({
        value: input.value.country_iso,
        dialCode: input.value.dial_code,
      });
    }
  }, [input.value]);

  const onCountryChange = (country) => {
    setCurrentPhoneCountry(country);
    input.onChange({
      ...input.value,
      country_iso: country.code,
      dial_code: country.dialCode,
    });
  };

  const onChange = ({ target }) => {
    setIsAutofill(target.matches(':autofill') || target.matches(':-webkit-autofill'));
    input.onChange({
      number: parseIncompletePhoneNumber(target.value),
      country_iso: currentPhoneCountry.code,
      dial_code: currentPhoneCountry.dialCode,
    });
  };

  const onBlur = ({ target }) => {
    input.onBlur({
      number: parseIncompletePhoneNumber(target.value),
      country_iso: currentPhoneCountry.code,
      dial_code: currentPhoneCountry.dialCode,
    });
    if (hasAsyncValidation) form.mutators.triggerValidation(input.name);
  };

  return (
    <PhoneNumberView
      input={input}
      label={label}
      hint={hint}
      placeholder={placeholder}
      meta={meta}
      currentCountry={currentPhoneCountry}
      onChange={onChange}
      onBlur={onBlur}
      countryOptions={countryOptions}
      onCountryChange={onCountryChange}
      isAutofill={isAutofill}
    />
  );
};

PhoneNumberContainer.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  hasAsyncValidation: PropTypes.bool.isRequired,
};

PhoneNumberContainer.defaultProps = {
  hint: null,
};

export default PhoneNumberContainer;
