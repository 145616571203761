import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import {
  FieldHint,
  FieldLabel,
  FieldError,
} from 'RegistrationV2/common/components';

const AddressFinderInput = ({
  input,
  label,
  meta: { active, touched, error },
  hasAddressControlError,
  onChange,
  onBlur,
  onFocus,
  hint,
  tooltipText,
  tooltipTitle,
  searchText,
}) => {
  const inputFieldClassNames = classnames(
    'regv2-form-control', {
      error: (error && touched) || hasAddressControlError,
      active,
    },
  );

  const showError = () => {
    if (!active && !hasAddressControlError) {
      return (
        <FieldError showError={touched} error={error} />
      );
    }

    if (hasAddressControlError) {
      return (
        <span className="regv2-form-error-msg">
          {t('javascript.account_form.address_finder_unavailable')}
        </span>
      );
    }
    return null;
  };

  return (
    <div className="regv2-form-group">
      <FieldLabel
        label={label}
        htmlFor={`account_${input.name}`}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      <input
        className={inputFieldClassNames}
        type="text"
        autoComplete="off"
        maxLength="100"
        id="address_search"
        placeholder={t('registration.field.addressFinder.placeholder')}
        onChange={onChange}
        onBlur={() => {
          input.onBlur();
          onBlur();
        }}
        onFocus={() => {
          input.onFocus();
          onFocus();
        }}
        value={searchText}
      />
      <FieldHint active hint={hint} />
      {showError()}
    </div>
  );
};

AddressFinderInput.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  hint: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
  hasAddressControlError: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  searchText: PropTypes.string,
};

AddressFinderInput.defaultProps = {
  hasAddressControlError: false,
  onChange: null,
  onBlur: null,
  onFocus: null,
  hint: null,
  tooltipTitle: null,
  tooltipText: null,
  searchText: '',
};

export default AddressFinderInput;
