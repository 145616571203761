import PropTypes from 'prop-types';
import { FlagListSelect } from 'RegistrationV2/common/components/index';
import {
  FieldHint,
  FieldLabel,
  FieldError,
} from 'RegistrationV2/common/components';

const NationalityView = ({
  input,
  label,
  nationalityOptions,
  meta: {
    touched, error, dirtySinceLastSubmit, submitError, active,
  },
  onNationalityChange,
  tooltipTitle,
  tooltipText,
  hint,
  placeholder,
}) => {
  const showError = (!active && touched && error) || (!dirtySinceLastSubmit && submitError);

  const selectedNationality = input.value && nationalityOptions.find(
    (n) => n.value === input.value,
  );

  return (
    <div className="regv2-form-group">
      <FieldLabel
        label={label}
        htmlFor={`account_${input.name}`}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      <FlagListSelect
        options={nationalityOptions}
        selectedOption={selectedNationality}
        onOptionChange={onNationalityChange}
        inputName={input.name}
        placeholder={placeholder}
      />
      <FieldHint active={!showError} hint={hint} />
      <FieldError showError={showError} error={error} />
    </div>
  );
};

NationalityView.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  nationalityOptions: PropTypes.arrayOf(Object).isRequired,
  onNationalityChange: PropTypes.func.isRequired,
  hint: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
  placeholder: PropTypes.string,
};

NationalityView.defaultProps = {
  hint: null,
  tooltipTitle: null,
  tooltipText: null,
  placeholder: null,
};

export default NationalityView;
