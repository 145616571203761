import { postJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';
import { property } from 'underscore';

// TODO: Probably we would need to send additional params that will
// be required to create the authentication context in the backend
export default ({ iobb, action }) => (
  postJSON(`/api/${locale()}/one_auth/url`, {
    iobb,
    _action: action,
  }).catch(property('body'))
);
