import {
  useMemo, useRef, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { ModalWindowContext } from 'bv-contexts';
import {
  shouldShowDropupList,
  initScrollableOffset,
  initBodyScroll,
} from 'RegistrationV2/helpers/dropdowns';
import DropdownContext from './dropdown_context';
import DropdownView from './dropdown_view';

const DropdownContainer = ({
  input,
  label,
  meta,
  values,
  onChange,
  default: defaultValue,
  hint,
  currentCountry,
  tooltipText,
  tooltipTitle,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [optionSearch, setOptionSearch] = useState(null);
  const [dropUpList, setDropUpList] = useState(false);
  const [scrollableOffset, setScrollableOffset] = useState(0);

  const inputRef = useRef();
  const listRef = useRef();

  const { scrollableRef } = useContext(ModalWindowContext);

  const onListClick = () => setShowOptions(!showOptions);
  const onListBlur = () => setShowOptions(false);

  const onOptionsSearch = ({ target }) => {
    setOptionSearch(target.value);
    setShowOptions(true);
  };

  const onOptionChange = (value) => {
    input.onChange(value);
    onChange(value);
  };

  const onMouseDown = (event, option) => {
    event.preventDefault();
    setOptionSearch(null);
    onOptionChange(option.value);
  };

  const onContainerMouseDown = (event) => {
    event.preventDefault();
    if (!showOptions) {
      inputRef.current.select();
    }
    onListClick();
  };

  const getOptionDescription = (value) => {
    if (input.name !== 'stateIsoCode' && input.name !== 'countyIsoCode') return value;
    return t(`registration.${input.name}.${currentCountry.countryIsoCode}.${value}`);
  };

  const selectOptions = useMemo(() => (
    values.map((value) => ({
      value, description: getOptionDescription(value),
    }))
  ), [values]);

  const selectedOption = selectOptions.find((opt) => opt.value === input.value)
    || selectOptions.find((opt) => opt.value === defaultValue) || selectOptions[0];

  const filteredOptions = optionSearch
    ? selectOptions.filter((c) => c.description.toLowerCase().indexOf(optionSearch.toLowerCase(
    )) !== -1) : selectOptions;

  useEffect(() => {
    if (!input.value) {
      input.onChange(defaultValue || values[0]);
    }
  }, []);

  useEffect(() => {
    initScrollableOffset({ scrollableRef, setScrollableOffset });
  }, [scrollableRef]);

  useEffect(() => {
    setDropUpList(shouldShowDropupList({
      showOptions, scrollableRef, inputRef, scrollableOffset,
    }));

    if (showOptions && selectedOption) {
      const element = listRef.current.querySelector(`.option.${selectedOption.value}`);
      if (element) listRef.current.scrollTop = element.offsetTop;
    }
  }, [showOptions, selectedOption, scrollableRef, scrollableOffset]);

  useEffect(() => {
    initBodyScroll(listRef);
  }, []);

  const contextValue = useMemo(
    () => ({
      meta,
      dropUpList,
      showOptions,
      label,
      tooltipTitle,
      tooltipText,
      hint,
      inputRef,
      listRef,
      selectedOption,
      filteredOptions,
      optionSearch,
      onListBlur,
      onOptionsSearch,
      onMouseDown,
      onContainerMouseDown,
      name: input.name,
    }),
    [meta, dropUpList, showOptions, inputRef,
      listRef, selectedOption, filteredOptions, optionSearch],
  );

  return (
    <DropdownContext.Provider value={contextValue}>
      <DropdownView />
    </DropdownContext.Provider>
  );
};

DropdownContainer.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  default: PropTypes.string,
  hint: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
  currentCountry: PropTypes.instanceOf(Object).isRequired,
};

DropdownContainer.defaultProps = {
  values: [],
  onChange: () => {},
  default: null,
  hint: null,
  tooltipText: null,
  tooltipTitle: null,
};

export default DropdownContainer;
