import { t } from 'bv-i18n';
import { v } from 'bv';
import { Field } from 'react-final-form';
import ConditionBox from '../condition_box';

const body = v('marketingOptIn')
  && (
  <>
    <p>
      { t('javascript.account_form.marketing_opt_in_description') }
    </p>
    <p>
      { v('marketingSpecialOptInSentence') ? t('javascript.registration_path.marketing.footer') : t('javascript.account_form.marketing_remember_preferences') }
    </p>
  </>
  );

const OptInConditionBox = (props) => (
  <Field
    {...props}
    component={ConditionBox}
    body={body}
    name="marketingOptIn"
  />

);

export default OptInConditionBox;
