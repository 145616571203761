import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { noop } from 'underscore';
import { v as bvVar } from 'bv';
import { useFetch } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { getBlackBox } from 'bv-services';
import { setFragment } from 'bv-helpers/location';

import handleLoginSuccess from 'Login/handlers/login_success';
import fetchUrl from './api/fetch_url';
import oneAuthParamsReducer, { setOneAuthParams } from './ducks/one_auth_params';

const { reduxState: { addReducers } } = window;

addReducers({
  registrationOneAuthParams: oneAuthParamsReducer,
});

const userAgentClientHints = bvVar('userAgentClientHints')
  .map((hint) => hint.toLowerCase().replace(/^(sec-)/, ''))
  .join(';');

const OneAuth = ({ action, showSpinner, setRedirecting }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [iframeLoading, setIframeLoading] = useState(true);
  const [url, fetching] = useFetch(
    async () => fetchUrl({
      action,
      iobb: await getBlackBox(),
    }),
  );

  useEffect(() => {
    const listener = (event) => {
      const { type, redirect, params } = event.data;

      if (type === 'callback/create_session') {
        handleLoginSuccess({ redirect });
        setRedirecting(true);
      } else if (type === 'callback/create_account') {
        dispatch(setOneAuthParams(params));
        if (pathname.startsWith('/login')) {
          setFragment('/account/new');
        }
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  // TODO: Proper styling / handling of errors
  if (!fetching && url.error) return <div>{url.error}</div>;

  return (
    <>
      {showSpinner && (fetching || iframeLoading) && <Spinner />}
      {!fetching && (
        <iframe
          src={url.url}
          onLoad={() => { setIframeLoading(false); }}
          width="100%"
          style={iframeLoading ? { display: 'none' } : {}}
          className="oneauth-iframe"
          allow={userAgentClientHints}
        />
      )}
    </>
  );
};

OneAuth.propTypes = {
  action: PropTypes.oneOf(['login', 'connect']).isRequired,
  showSpinner: PropTypes.bool,
  setRedirecting: PropTypes.func,
};

OneAuth.defaultProps = {
  showSpinner: true,
  setRedirecting: noop,
};

export default OneAuth;

// TODO: I guess height and width to be done via CSS
// Based on className created using the action
