import { t } from 'bv-i18n';

const countDigits = (value) => {
  if (typeof value === 'string') {
    const matches = value.match(/\d/g);
    return matches ? matches.length : 0;
  } if (typeof value === 'number') {
    return 0;
  }
  return -1;
};

export default ({ errorMessage }) => (value) => {
  const digitsCount = countDigits(value);
  return digitsCount >= 1 ? t(`${errorMessage}`) : undefined;
};
