import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import { FlagListSelect } from 'RegistrationV2/common/components/index';
import {
  FieldHint,
  FieldLabel,
  FieldError,
} from 'RegistrationV2/common/components';

const PhoneNumberView = ({
  input, label, hint, countryOptions, currentCountry, meta,
  onChange, onCountryChange, onBlur, placeholder, tooltipText,
  tooltipTitle, isAutofill,
}) => {
  const {
    touched, error, active, submitError, dirtySinceLastSubmit,
  } = meta;
  const showError = !active && ((touched && error) || (!dirtySinceLastSubmit && submitError));

  return (
    <div className="regv2-form-group">
      <div className="regv2-form-group__phone">
        <div className="regv2-form-group__phone--country-code">
          <span className="regv2-form-control-label">{t('javascript.account_form.country')}</span>
          <FlagListSelect
            options={countryOptions}
            selectedOption={currentCountry}
            onOptionChange={onCountryChange}
            inputName={input.name}
          />
        </div>
        <div
          className={classnames('regv2-form-group__phone--number', {
            'is-autofill': isAutofill,
          })}
        >
          <FieldLabel
            label={label}
            htmlFor={input.name}
            tooltipTitle={tooltipTitle}
            tooltipText={tooltipText}
          />
          <input
            {...input}
            id={input.name}
            type="tel"
            className={classnames('regv2-form-control', {
              error: showError,
              active,
              'with-value': !!(input.value?.number),
            })}
            autoCapitalize="off"
            autoCorrect="off"
            value={input.value && input.value.number
              && formatIncompletePhoneNumber(input.value.number, currentCountry.code.toUpperCase(),
              )}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
          />
        </div>
      </div>
      <FieldHint active={!showError} hint={hint} />
      <FieldError showError={showError} error={submitError || error} />
    </div>
  );
};

PhoneNumberView.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  countryOptions: PropTypes.arrayOf(Object).isRequired,
  currentCountry: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string,
  tooltipText: PropTypes.string,
  isAutofill: PropTypes.bool,
};

PhoneNumberView.defaultProps = {
  hint: null,
  tooltipTitle: null,
  tooltipText: null,
  isAutofill: false,
};

export default PhoneNumberView;
