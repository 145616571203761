import { getJSON } from 'bv-fetch';

export const fetchConfigurations = () => {
  const path = '/api/registration/configuration';
  return getJSON(path).catch(() => ({
    error: true,
    status: '500',
  }));
};

export const fetchDepositLimitOptions = (countryId, currencyId) => getJSON(
  '/api/deposit_limits/options',
  { country_id: countryId, currency_id: currencyId },
).catch(() => ({
  options: [],
  defaultValue: null,
}));
