import PropTypes from 'prop-types';
import OneAuth from 'SharedComponents/one_auth';

const OneAuthConnect = ({ setRedirecting }) => (
  <OneAuth action="connect" showSpinner={false} setRedirecting={setRedirecting} />
);

OneAuthConnect.propTypes = {
  setRedirecting: PropTypes.func.isRequired,
};

export default OneAuthConnect;
