import AddressFinder from 'RegistrationV2/components/fields/address/address_finder_container';
import BasicNumber from 'RegistrationV2/components/fields/basic_number';
import BasicText from 'RegistrationV2/components/fields/basic_text';
import CountrySelectField from 'RegistrationV2/components/fields/country_select/country_select_container';
import CurrencyField from 'RegistrationV2/components/fields/currency/currency_field_container';
import DateOfBirth from 'RegistrationV2/components/fields/date_of_birth';
import DepositLimit from 'RegistrationV2/components/fields/deposit_limit';
import Dropdown from 'RegistrationV2/components/fields/dropdown/dropdown_container';
import Email from 'RegistrationV2/components/fields/email';
import Enum from 'RegistrationV2/components/fields/enum';
import NationalityField from 'RegistrationV2/components/fields/nationality/nationality_container';
import Password from 'RegistrationV2/components/fields/password_with_error_list_V2';
import PhoneNumber from 'RegistrationV2/components/fields/phone_number/phone_number_container';

export default (type) => ({
  addressFinder: AddressFinder,
  country: CountrySelectField,
  currencyIsoCode: CurrencyField,
  depositLimit: DepositLimit,
  date: DateOfBirth,
  dropdown: Dropdown,
  email: Email,
  enum: Enum,
  citizenshipIsoCode: NationalityField,
  identityCardNumber: BasicText,
  identityCard: BasicText,
  number: BasicNumber,
  password: Password,
  mobile: PhoneNumber,
  select: Dropdown,
  text: BasicText,
}[type]);
