import { useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { escapeInput } from 'RegistrationV2/helpers/text_utils';
import {
  FieldHint,
  FieldError,
  FieldLabel,
} from 'RegistrationV2/common/components';

const Email = ({
  input, label, hint, placeholder, meta: {
    error, active, touched, dirtySinceLastSubmit,
  }, tooltipTitle, tooltipText, hasAsyncValidation,
}) => {
  const form = useForm();
  const { submitErrors } = useFormState();
  const submitError = submitErrors && submitErrors[input.name];
  const showError = !active && (touched || submitError);

  const fieldError = useMemo(
    () => error || (!dirtySinceLastSubmit && submitError),
    [error, submitError, dirtySinceLastSubmit],
  );

  const inputFieldClassNames = classnames(
    'regv2-form-control', {
      error: fieldError && showError,
      active,
      'with-value': input.value,
    },
  );

  const onChange = ({ target: { value } }) => input.onChange(escapeInput(value));

  const onBlur = () => {
    input.onBlur(input.value);
    if (hasAsyncValidation) form.mutators.triggerValidation(input.name);
  };

  return (
    <div className="regv2-form-group">
      <FieldLabel
        label={label}
        htmlFor={input.name}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      <input
        {...input}
        type={input.type}
        className={inputFieldClassNames}
        id="regpath_form_email_input"
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="off"
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      <FieldHint active={!showError} hint={hint} />
      <FieldError showError={showError} error={fieldError} />
      <div className="g-recaptcha" />
    </div>
  );
};

Email.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
  placeholder: PropTypes.string,
  hasAsyncValidation: PropTypes.bool.isRequired,
};

Email.defaultProps = {
  hint: null,
  tooltipTitle: null,
  tooltipText: null,
  placeholder: null,
};

export default Email;
