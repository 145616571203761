import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  FieldHint,
  FieldLabel,
  FieldError,
} from 'RegistrationV2/common/components';

const BasicNumber = ({
  input,
  label,
  hint,
  meta: {
    touched, error, active,
  },
}) => {
  const inputFieldClassNames = classnames(
    'regv2-form-control', {
      error: error && touched,
      'with-value': input.value && input.value !== '',
      active,
    },
  );

  const filterInput = (ev) => {
    if (['e', 'E', ' ', '-'].includes(ev.key)) ev.preventDefault();
  };

  const showError = (!active && touched && error);

  return (
    <div className="regv2-form-group">
      <FieldLabel label={label} htmlFor={input.name} />
      <input
        {...input}
        id={input.name}
        type={input.type}
        className={inputFieldClassNames}
        autoCapitalize="off"
        autoCorrect="off"
        onKeyPress={filterInput}
      />
      <FieldHint active={!showError} hint={hint} />
      <FieldError showError={showError} error={error} />
    </div>
  );
};

BasicNumber.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  hint: PropTypes.string,
};

BasicNumber.defaultProps = {
  hint: null,
};

export default BasicNumber;
