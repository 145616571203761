import {
  enable as enableBodyScroll,
  disable as disableBodyScroll,
} from 'bv-services/body-scroll-manager';

export const shouldShowDropupList = ({
  showOptions, scrollableRef, inputRef, scrollableOffset,
}) => {
  if (showOptions && scrollableRef && scrollableRef.current && inputRef && inputRef.current) {
    const elementOffset = inputRef.current.getBoundingClientRect().top;
    const { offsetHeight } = scrollableRef.current;
    const { height: windowHeight } = window.screen;
    const modalContainerscrollPercentage = (
      (elementOffset - scrollableOffset) / offsetHeight
    ) * 100;

    if (elementOffset && elementOffset < 150) {
      return false;
    } if (elementOffset && elementOffset > windowHeight - 100) {
      return true;
    }
    return modalContainerscrollPercentage > 50;
  }
  return false;
};

export const initScrollableOffset = ({ scrollableRef, setScrollableOffset }) => {
  const updateScrollableOffset = () => {
    if (scrollableRef && scrollableRef.current) {
      setScrollableOffset(scrollableRef.current.getBoundingClientRect().top);
    }
  };

  updateScrollableOffset();
  window.addEventListener('resize', updateScrollableOffset);

  return () => {
    window.removeEventListener('resize', updateScrollableOffset);
  };
};

export const initBodyScroll = (listRef) => {
  const listElement = listRef.current;
  disableBodyScroll(listElement);
  return () => enableBodyScroll(listElement);
};
