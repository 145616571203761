import { Spinner, Overlay, RenderInBody } from 'bv-components';

const LoadingOverlay = () => (
  <>
    <Overlay fullscreen />
    <RenderInBody>
      <div className="regv2-loading-wrapper">
        <Spinner big />
      </div>
    </RenderInBody>
  </>
);

export default LoadingOverlay;
