import PropTypes from 'prop-types';
import { FlagListSelect } from 'RegistrationV2/common/components/index';
import FormField from 'RegistrationV2/components/form_kinds/registration/form_step/fields/form_field';
import { t } from 'bv-i18n';
import {
  FieldHint,
  FieldLabel,
  FieldError,
} from 'RegistrationV2/common/components';

const CountrySelectView = ({
  input,
  label,
  countryOptions,
  meta: {
    touched, error, dirtySinceLastSubmit, submitError, active,
  },
  onCountryChange,
  onStateChange,
  currentCountry,
  currentState,
  hasStateSelection,
  hint,
  tooltipText,
  tooltipTitle,
}) => {
  const showError = (!active && touched && error) || (!dirtySinceLastSubmit && submitError);
  const selectedCountry = countryOptions.find(
    (c) => c.value === currentCountry.countryIsoCode,
  ) || countryOptions[0];

  const { stateIsoCodes = [] } = currentCountry;

  return (
    <div className="regv2-form-group">
      <FieldLabel
        label={label}
        htmlFor={`account_${input.name}`}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      <FlagListSelect
        options={countryOptions}
        selectedOption={selectedCountry}
        onOptionChange={onCountryChange}
        inputName={input.name}
      />
      <FieldHint active={!showError} hint={hint} />
      <FieldError showError={showError} error={error} />
      {hasStateSelection && (
        <FormField
          key="stateIsoCode"
          name="stateIsoCode"
          label={t('registration.field.stateIsoCode.label')}
          type="select"
          validations={[{
            type: 'notNull',
            errorMessage: t('registration.field.stateIsoCode.notNull.errorMessage'),
          }]}
          hint={t('registration.field.stateIsoCode.hint')}
          values={stateIsoCodes}
          onChange={onStateChange}
          defaultValue={currentState}
        />
      )}
    </div>
  );
};

CountrySelectView.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  countryOptions: PropTypes.arrayOf(Object).isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  currentCountry: PropTypes.instanceOf(Object).isRequired,
  currentState: PropTypes.string,
  hasStateSelection: PropTypes.bool.isRequired,
  hint: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

CountrySelectView.defaultProps = {
  hint: null,
  tooltipTitle: null,
  tooltipText: null,
  currentState: null,
};

export default CountrySelectView;
