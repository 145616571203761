import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'underscore';
import { t } from 'bv-i18n';
import { FieldError } from 'RegistrationV2/common/components';
import { renderBodyText } from 'RegistrationV2/helpers/text_utils';

const BaseConditionBox = ({
  input,
  meta: { touched, error },
  text,
  body,
}) => {
  const checkboxStyles = classnames('regv2-form__checkbox', {
    active: input.value === true,
  });

  const conditionStyles = classnames('regv2-form-control regv2-condition-box', {
    error: touched && !isEmpty(error),
  });

  return (
    <div className="regv2-form-group">
      <div className={conditionStyles}>
        <label htmlFor={input.name} className={checkboxStyles}>
          <input {...input} type="checkbox" id={input.name} checked={!!input.value} />
          <span className="checkmark" />
        </label>
        <div className="regv2-condition-box__content">
          <p className="regv2-condition-box__title">
            {t(`registration.field.${input.name}.title`)}
          </p>
          {body || renderBodyText(t(text || `registration.field.${input.name}.text`))}
        </div>
      </div>
      <FieldError showError={touched} error={error} />
    </div>
  );
};

BaseConditionBox.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  text: PropTypes.string,
  body: PropTypes.element,
};

BaseConditionBox.defaultProps = {
  text: undefined,
  body: null,
};

export default BaseConditionBox;
