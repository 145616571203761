import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import CurrencyFieldView from './currency_field_view';

const CurrencyFieldContainer = (props) => {
  const { input, values } = props;

  const onCurrencyChange = ({ value }) => input.onChange(value);

  useEffect(() => {
    if (!input.value) {
      input.onChange(values[0]);
    }
  }, []);

  const currencyOptions = useMemo(() => (
    values.map((value) => ({
      value,
      text: t(`registration.currencyIsoCode.${value}`),
    }))
  ), [values]);

  return (
    <CurrencyFieldView
      onCurrencyChange={onCurrencyChange}
      currencyOptions={currencyOptions}
      {...props}
    />
  );
};

CurrencyFieldContainer.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.arrayOf(String).isRequired,
};

export default CurrencyFieldContainer;
