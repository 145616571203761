import PropTypes from 'prop-types';

const FieldHint = ({ active, hint }) => (
  active && hint && <span className="regv2-form-hint-msg">{hint}</span>
);

FieldHint.propTypes = {
  active: PropTypes.bool,
  hint: PropTypes.string,
};

FieldHint.defaultProps = {
  active: false,
  hint: null,
};

export default FieldHint;
