import { Spinner } from 'bv-components';
import PropTypes from 'prop-types';
import withRegModal from 'RegistrationV2/hocs/with_reg_modal';

const PreviewApp = ({ loading }) => (
  <div className="regv2_preview_app">
    {loading && (
      <Spinner big />
    )}
  </div>
);

PreviewApp.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default withRegModal('sign_up')(PreviewApp);
