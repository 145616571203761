import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { cmsAssetPath } from 'bv';
import { Field, useForm, useFormState } from 'react-final-form';
import { getValidationFunction } from 'RegistrationV2/validations';
import formField from 'RegistrationV2/factories/form_field';
import { getCurrentCountry, getRegistrationId } from 'RegistrationV2/ducks';

const FormField = (props) => {
  const {
    type, validations, name, defaultValue, imageGuide,
    label, hint, placeholder, tooltipTitle, tooltipText,
  } = props;

  const form = useForm();
  const { values: formValues } = useFormState();
  const { identity_provider: identityProvider } = formValues;
  const currentCountry = useSelector(getCurrentCountry);
  const registrationId = useSelector(getRegistrationId);

  const Component = useMemo(() => {
    if ([
      'currencyIsoCode',
      'citizenshipIsoCode',
    ].includes(name)) return formField(name);

    return formField(type);
  }, [type, name]);
  if (!Component) return null;

  const asyncValidation = validations?.find((v) => v.type === 'api');
  const validator = getValidationFunction(type, validations, form, registrationId, asyncValidation);

  const translatedProps = {
    label: label ? t(label) : undefined,
    hint: hint ? t(hint) : undefined,
    placeholder: placeholder ? t(placeholder) : undefined,
    tooltipTitle: tooltipTitle ? t(tooltipTitle) : undefined,
    tooltipText: tooltipText ? t(tooltipText) : undefined,
  };

  if (name === 'password') {
    return identityProvider ? null : (
      <Component {...props} {...translatedProps} />
    );
  }

  return (
    <Field
      {...props}
      component={Component}
      validate={validator}
      default={defaultValue}
      currentCountry={currentCountry}
      imageGuide={imageGuide && cmsAssetPath(imageGuide)}
      hasAsyncValidation={asyncValidation}
      {...translatedProps}
    />
  );
};

FormField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(Object).isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipText: PropTypes.string,
  imageGuide: PropTypes.string,
};

FormField.defaultProps = {
  defaultValue: null,
  label: null,
  hint: null,
  tooltipTitle: null,
  tooltipText: null,
  placeholder: null,
  imageGuide: null,
};

export default FormField;
