import * as validationMethods from './methods';
import apiValidation from './api';

const getValidationFunctions = (validations) => validations.reduce((acc, validation) => {
  if (validation.type === 'api') return acc;
  const method = validationMethods[validation.type];
  if (method) return [...acc, method(validation)];
  return acc;
}, []);

export const getValidationFunction = (type, validations, form, registrationId, asyncValidation) => {
  if (!validations) return () => {};

  const validationFns = getValidationFunctions(validations);

  const getCurrentValue = (formValue) => {
    if (type === 'mobile') return formValue?.number ? `+${formValue?.dial_code}${formValue?.number}` : null;
    return formValue;
  };

  return (formValue, allFormValues, meta) => {
    const currentValue = getCurrentValue(formValue);

    for (let i = 0; i < validationFns.length; i += 1) {
      const error = validationFns[i](currentValue, allFormValues);
      if (error) return error;
    }
    if (asyncValidation && !meta.active) {
      return apiValidation({
        field: type,
        currentValue,
        registrationId,
      });
    }
    return undefined;
  };
};
