import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { ModalWindowContext } from 'bv-contexts';
import { isHCaptcha } from 'bv-services';
import { useScrollToTop } from 'bv-hooks';
import { t } from 'bv-i18n';

import OptInConditionBox from 'RegistrationV2/components/fields/marketing/opt_in_condition_box';
import TermsPrivacyPolicy from 'RegistrationV2/components/fields/terms_privacy_policy';
import HCaptcha from 'SharedComponents/hcaptcha';
import { selectHCaptchaCompleted } from 'SharedComponents/hcaptcha/selectors';
import FormFieldRow from '../fields/form_field_row';

const LastStep = ({ rows }) => {
  const { scrollableRef } = useContext(ModalWindowContext);
  useScrollToTop([scrollableRef.current], scrollableRef.current);

  const { submitting } = useFormState();
  const hCaptchaCompleted = useSelector(selectHCaptchaCompleted);

  return (
    <>
      {rows.map((row) => (<FormFieldRow row={row} />))}
      <OptInConditionBox />
      <TermsPrivacyPolicy />
      <HCaptcha />
      <button
        type="submit"
        className="regv2-button-submit bvs-button is-secondary"
        disabled={submitting || (isHCaptcha && !hCaptchaCompleted)}
      >
        {t('registration.steps.buttons.final_step')}
      </button>
    </>
  );
};

LastStep.propTypes = {
  rows: PropTypes.arrayOf(Object).isRequired,
};

export default LastStep;
