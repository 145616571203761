import { createSelector } from 'reselect';
import { pluck } from 'underscore';

export const getRegistrationStore = (state) => state.registrationV2 || {};

export const getCurrentAction = (state) => state.registrationV2.currentAction;
export const getCountryIsoCode = (state) => state.registrationV2.countryIsoCode;
export const getRegistrationId = (state) => state.registrationV2.registrationId;
export const getStandard = (state) => state.registrationV2.standard;
export const getLoading = (state) => state.registrationV2.loading;
export const getCountries = (state) => state.registrationV2.countries;
export const getProductId = (state) => state.registrationV2.productId;
export const getDepositLimitOptions = (state) => state.registrationV2.depositLimits;
export const getPhoneNumberCountries = (state) => state.registrationV2.phoneNumberCountries;
export const getCurrentCountry = (state) => state.registrationV2.currentCountry;
export const getCurrentStep = (state) => state.registrationV2.currentStep;
export const getCurrentState = (state) => state.registrationV2.currentState;
export const getShowLoadingOverlay = (state) => state.registrationV2.showLoadingOverlay;

export const getAllSteps = createSelector(
  getStandard,
  (standard) => standard.steps || [],
);

export const getDefaultCountry = createSelector(
  getCountries,
  getCountryIsoCode,
  (countries, defaultCountryIsoCode) => (
    countries.find((c) => (c.countryIsoCode === defaultCountryIsoCode)) || countries[0]
  ),
);

export const getLastStepVisitedNumber = createSelector(
  getRegistrationStore,
  (store) => store.lastStepVisitedNumber || 1,
);

export const getHasStateSelection = createSelector(
  getCurrentCountry,
  (country) => country?.stateRequired && !!country.stateIsoCodes.length,
);

export const getAllFields = createSelector(
  getAllSteps,
  (steps) => {
    const fields = steps.map((step) => [...step.rows.map((row) => row.fields)].flat()).flat();
    return [...pluck(fields, 'name'), 'marketingOptIn', 'termsPrivacyPolicy'];
  },
);

export const getCurrencies = createSelector(
  getAllFields,
  (fields) => {
    const currencyField = fields.find(({ name }) => name === 'currencyIsoCode');
    return currencyField?.values || [];
  },
);
