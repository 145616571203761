import createErrorHandler from './create_error_handler';

const { actionDispatchers } = window.reduxState.store;

export const handleApiError = ({
  type = 'connect',
  errorCode = '',
  onRetry,
  message,
}) => {
  if (errorCode === 'AC-148') {
    return createErrorHandler({
      errorType: 'expiration_error',
      onRetry: actionDispatchers.registration.refreshRegistration,
    });
  } if (type === 'submit') {
    return createErrorHandler({ errorType: 'submit_error', message, onRetry });
  }
  return createErrorHandler({ errorType: 'connection_error', onRetry });
};
