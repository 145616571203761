import { useSelector, useDispatch } from 'react-redux';

import {
  setIsShowDepositLimit,
  setNextAction,
  getCurrentAction,
} from 'RegistrationV2/ducks';
import BonusSelectionView from './bonus_selection_view';

const BonusSelectionContainer = (props) => {
  const currentAction = useSelector(getCurrentAction);

  const dispatch = useDispatch();

  const moveToDeposit = () => {
    dispatch(setNextAction());
    dispatch(setIsShowDepositLimit(true));
  };

  return (
    <BonusSelectionView
      currentAction={currentAction}
      optInHandler={moveToDeposit}
      onClose={moveToDeposit}
      {...props}
    />
  );
};

export default BonusSelectionContainer;
