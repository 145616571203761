import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { destroyCaptchaServices, loadCaptchaServices } from 'bv-services';
import {
  startRegistration,
  getLoading,
  getCurrentAction,
} from 'RegistrationV2/ducks';
import { loadFeatureFlipperDependencies } from 'RegistrationV2/helpers/dependencies';
import FormKind from 'RegistrationV2/factories/form_kind';
import PreviewApp from './preview_app';

const AppContainer = () => {
  const loading = useSelector(getLoading);
  const currentAction = useSelector(getCurrentAction);

  const dispatch = useDispatch();

  const [dependenciesLoaded, setDependenciesLoaded] = useState(false);

  useEffect(() => {
    dispatch(startRegistration());
    document.documentElement.classList.add('signup-view-launched');

    return () => {
      document.documentElement.classList.remove('signup-view-launched');
    };
  }, []);

  useEffect(() => {
    Promise.all([
      ...loadFeatureFlipperDependencies(),
      loadCaptchaServices(),
    ])
      .then(() => setDependenciesLoaded(true));
    return destroyCaptchaServices;
  }, []);

  if (loading || !currentAction || !dependenciesLoaded) {
    return <PreviewApp loading={loading} />;
  }

  return <FormKind action={currentAction} />;
};

export default AppContainer;
