import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import {
  getCountries,
  getCurrentCountry,
  getCurrentState,
  getHasStateSelection,
  changeStandard,
  setCurrentCountry,
  setCurrentState,
} from 'RegistrationV2/ducks';

import CountrySelectView from './country_select_view';

const CountrySelectContainer = (props) => {
  const { input } = props;

  const dispatch = useDispatch();
  const form = useForm();

  const countries = useSelector(getCountries);
  const currentCountry = useSelector(getCurrentCountry);
  const currentState = useSelector(getCurrentState);
  const hasStateSelection = useSelector(getHasStateSelection);

  const fetchNewStandard = async (onRetry) => {
    dispatch(changeStandard(onRetry));
  };

  const onCountryChange = ({ value }) => {
    const { countryIsoCode } = currentCountry;
    if (countryIsoCode === value) return;

    const country = countries.find((c) => c.countryIsoCode === value);
    input.onChange(value);
    dispatch(setCurrentCountry(country));
    if (!country.stateRequired) {
      form.change('stateIsoCode', null);
      dispatch(setCurrentState(null));
      fetchNewStandard(() => onCountryChange({ value }));
    } else {
      if (!currentState) dispatch(setCurrentState(country.stateIsoCodes[0]));
      fetchNewStandard(() => onCountryChange({ value }));
    }
  };

  const onStateChange = (state) => {
    if (state === currentState) return;
    dispatch(setCurrentState(state));
    fetchNewStandard(() => onStateChange(state));
  };

  useEffect(() => {
    if (!input.value) {
      input.onChange(currentCountry.countryIsoCode);
    }
  }, []);

  const countryOptions = useMemo(() => (
    countries.map((c) => ({
      value: c.countryIsoCode,
      text: t(`registration.countryIsoCode.${c.countryIsoCode}`),
    }))
  ), [countries]);

  return (
    <CountrySelectView
      onCountryChange={onCountryChange}
      onStateChange={onStateChange}
      hasStateSelection={hasStateSelection}
      countryOptions={countryOptions}
      currentCountry={currentCountry}
      currentState={currentState}
      {...props}
    />
  );
};

CountrySelectContainer.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default CountrySelectContainer;
