import { postJSON } from 'bv-fetch';
import { getBlackBox } from 'bv-services';

const getPosition = () => {
  const buildPosition = (position) => ({
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
  });

  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(buildPosition(position)),
      /* eslint-disable-next-line prefer-promise-reject-errors */
      () => resolve({ latitude: null, longitude: null }),
    );
  });
};

export default async () => {
  const position = await getPosition();
  const iobb = await getBlackBox();

  const params = new URLSearchParams(Object.fromEntries(
    Object.entries({
      ...position,
      iobb,
    }).filter(([, v]) => v !== null && v !== 'null'),
  ));

  const path = '/api/registration/account/start';
  return postJSON(path, params).catch(() => ({
    error: true,
    status: '500',
  }));
};
