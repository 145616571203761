import { t } from 'bv-i18n';

export default ({ errorMessage, value: allowedSymbols }) => (input) => {
  const error = t(errorMessage);
  const escapedSymbols = allowedSymbols.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  if (typeof input === 'string') {
    const regex = new RegExp(`^[a-zA-Z\\d${escapedSymbols}\\u4e00-\\u9fa5]+$`);
    return regex.test(input) ? undefined : error;
  }
  return undefined;
};
