import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useScrollToTop } from 'bv-hooks';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { ModalWindowContext } from 'bv-contexts';
import FormFieldRow from '../fields/form_field_row';
import FormField from '../fields/form_field';

const GeneralSteps = ({
  rows, currentStep, isFirstStep,
}) => {
  const countryValidations = [
    { type: 'notNull', errorMessage: 'registration.field.countryIsoCode.notNull.errorMessage' },
  ];

  const { scrollableRef } = useContext(ModalWindowContext);
  useScrollToTop([currentStep, scrollableRef.current], scrollableRef.current);

  return (
    <>
      {isFirstStep && (
        <div className="regv2-field-wrapper regv2-field-wrapper-full-width">
          <FormField
            key="countryIsoCode"
            name="countryIsoCode"
            label="registration.field.countryIsoCode.label"
            type="country"
            hint={t('registration.field.countryIsoCode.hint')}
            validations={countryValidations}
          />
        </div>
      )}
      {rows.map((row) => (<FormFieldRow row={row} />))}
      <Button
        className="regv2-button-next"
        noClass
        type="regv2-button-next"
      >
        {t(`registration.steps.buttons.step${currentStep.number}`)}
      </Button>
    </>
  );
};

GeneralSteps.propTypes = {
  rows: PropTypes.arrayOf(Object).isRequired,
  currentStep: PropTypes.instanceOf(Object).isRequired,
  isFirstStep: PropTypes.bool,
};

GeneralSteps.defaultProps = {
  isFirstStep: false,
};

export default GeneralSteps;
