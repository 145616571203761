import PropTypes from 'prop-types';

const AddressOptions = ({
  onAddressOptionClick, addressOptions,
}) => {
  const onAddressOptionMouseDown = (event, opt) => {
    event.preventDefault();
    onAddressOptionClick(opt);
  };

  if (!addressOptions.length) return null;

  return (
    <div className="regv2-address-finder-container">
      <ul className="address-options-list">
        {addressOptions.map((option) => (
          <li
            key={option.id}
            className="address-option"
            onMouseDown={(evt) => onAddressOptionMouseDown(evt, option)}
          >
            <span className="option-text">{option.text}</span>
            <span className="option-description">
              {option.description}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

AddressOptions.propTypes = {
  onAddressOptionClick: PropTypes.func.isRequired,
  addressOptions: PropTypes.arrayOf(Object),
};

AddressOptions.defaultProps = {
  addressOptions: [],
};

export default AddressOptions;
