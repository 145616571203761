import { compose } from 'underscore';
import { handleApiError } from 'RegistrationV2/helpers/errors';
import { getFormValues } from 'RegistrationV2/local_storage';
import {
  START_REGISTRATION_FAILURE,
  START_REGISTRATION_SUCCESS,
  START_REGISTRATION_INIT,
  REFRESH_REGISTRATION_FAILURE,
  REFRESH_REGISTRATION_SUCCESS,
  REFRESH_REGISTRATION_INIT,
  FETCH_DEPOSIT_LIMIT_OPTIONS_INIT,
  FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS,
  CHANGE_STANDARD_FAILURE,
  CHANGE_STANDARD_INIT,
  CHANGE_STANDARD_SUCCESS,
  SET_CURRENT_STATE,
  SET_CURRENT_COUNTRY,
  SET_CURRENT_STEP,
  MOVE_TO_FIELD_STEP,
  RESET_FORM,
  SET_NEXT_STEP,
  SET_NEXT_ACTION,
  SET_IS_DEPOSIT_LIMIT,
  SET_SHOW_LOADING_OVERLAY,
} from './action_types';
import * as api from '../api';

export const startRegistrationInit = () => ({
  type: START_REGISTRATION_INIT,
});

export const startRegistrationSuccess = (response) => ({
  type: START_REGISTRATION_SUCCESS,
  response,
});

export const startRegistrationFailure = () => ({
  type: START_REGISTRATION_FAILURE,
});

export const refreshRegistrationInit = () => ({
  type: REFRESH_REGISTRATION_INIT,
});

export const refreshRegistrationSuccess = (response) => ({
  type: REFRESH_REGISTRATION_SUCCESS,
  ...response,
});

export const refreshRegistrationFailure = () => ({
  type: REFRESH_REGISTRATION_FAILURE,
});

export const changeStandardInit = () => ({
  type: CHANGE_STANDARD_INIT,
});

export const changeStandardFailure = () => ({
  type: CHANGE_STANDARD_FAILURE,
});

export const changeStandardSuccess = (configurations) => ({
  type: CHANGE_STANDARD_SUCCESS,
  ...configurations,
});

export const fetchDepositLimitOptionsInit = () => ({
  type: FETCH_DEPOSIT_LIMIT_OPTIONS_INIT,
});

export const fetchDepositLimitOptionsSuccess = (options) => ({
  type: FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS,
  options,
});

export const setCurrentStep = (step) => ({
  type: SET_CURRENT_STEP,
  step,
});

export const setCurrentCountry = (country) => ({
  type: SET_CURRENT_COUNTRY,
  country,
});

export const setCurrentState = (state) => ({
  type: SET_CURRENT_STATE,
  state,
});

export const changeStandard = (onRetry) => (dispatch, getState) => {
  const registrationState = getState().registrationV2;
  const { registrationId, countryIsoCode, currentState: stateIsoCode } = registrationState;
  dispatch(changeStandardInit());

  return api.fetchStandard({
    registrationId,
    countryIsoCode,
    stateIsoCode,
  })
    .then((response) => {
      if (response.error) {
        dispatch(changeStandardFailure());
        return handleApiError({ ...response, onRetry });
      }
      return dispatch(changeStandardSuccess(response));
    });
};

export const startRegistration = () => (dispatch, getState) => {
  const registrationState = getState().registrationV2;
  if (registrationState.loadedConfigs) {
    return dispatch(setCurrentStep(registrationState.standard?.steps[0]));
  }

  dispatch(startRegistrationInit());

  return Promise.all([api.startRegistration(), api.fetchConfigurations()])
    .then(([registration, configurations]) => {
      if (registration.error || configurations.error) {
        dispatch(startRegistrationFailure());
        return handleApiError({ onRetry: () => dispatch(startRegistration()) });
      }

      const { countryIsoCode, stateIsoCode } = getFormValues();

      if (countryIsoCode && (
        registration.countryIsoCode !== countryIsoCode
        || (stateIsoCode && registration.stateIsoCode !== stateIsoCode)
      )) {
        dispatch(startRegistrationSuccess({
          ...registration,
          ...configurations,
          countryIsoCode,
          stateIsoCode,
        }));
        return dispatch(changeStandard(changeStandard));
      }

      return dispatch(startRegistrationSuccess({ ...registration, ...configurations }));
    });
};

export const refreshRegistration = () => (dispatch, getState) => {
  const registrationState = getState().registrationV2;
  const { countryIsoCode, currentState: stateIsoCode } = registrationState;
  dispatch(refreshRegistrationInit());

  return api.startRegistration()
    .then((response) => {
      if (response.error) return dispatch(refreshRegistrationFailure());
      if (response.countryIsoCode !== countryIsoCode || (
        response.stateIsoCode && stateIsoCode && response.stateIsoCode !== stateIsoCode
      )) {
        dispatch(refreshRegistrationSuccess({
          ...response,
          countryIsoCode,
          stateIsoCode,
        }));
        return dispatch(changeStandard(changeStandard));
      }
      return dispatch(refreshRegistrationSuccess(response));
    });
};

export const fetchDepositLimitOptions = (country, currency) => (dispatch) => {
  dispatch(fetchDepositLimitOptionsInit());

  return api.fetchDepositLimitOptions(country, currency)
    .then(compose(dispatch, fetchDepositLimitOptionsSuccess));
};

export const setNextStep = (nextStep) => ({
  type: SET_NEXT_STEP,
  nextStep,
});

export const setNextAction = () => ({
  type: SET_NEXT_ACTION,
});

export const moveToField = (field) => ({
  type: MOVE_TO_FIELD_STEP,
  field,
});

export const setIsShowDepositLimit = (showDepositLimits) => ({
  type: SET_IS_DEPOSIT_LIMIT,
  showDepositLimits,
});

export const resetForm = () => ({
  type: RESET_FORM,
});

export const setShowLoadingOverlay = (state) => ({
  type: SET_SHOW_LOADING_OVERLAY,
  state,
});
