export const START_REGISTRATION_INIT = 'registrationV2/START_REGISTRATION_INIT';
export const START_REGISTRATION_SUCCESS = 'registrationV2/START_REGISTRATION_SUCCESS';
export const START_REGISTRATION_FAILURE = 'registrationV2/START_REGISTRATION_FAILURE';
export const REFRESH_REGISTRATION_INIT = 'registrationV2/REFRESH_REGISTRATION_INIT';
export const REFRESH_REGISTRATION_SUCCESS = 'registrationV2/REFRESH_REGISTRATION_SUCCESS';
export const REFRESH_REGISTRATION_FAILURE = 'registrationV2/REFRESH_REGISTRATION_FAILURE';
export const FETCH_DEPOSIT_LIMIT_OPTIONS_INIT = 'registrationV2/FETCH_DEPOSIT_LIMIT_OPTIONS_INIT';
export const FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS = 'registrationV2/FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS';

export const CHANGE_STANDARD_INIT = 'registrationV2/CHANGE_STANDARD_INIT';
export const CHANGE_STANDARD_SUCCESS = 'registrationV2/CHANGE_STANDARD_SUCCESS';
export const CHANGE_STANDARD_FAILURE = 'registrationV2/CHANGE_STANDARD_FAILURE';

export const SET_CURRENT_STEP = 'registrationV2/SET_CURRENT_STEP';
export const SET_CURRENT_COUNTRY = 'registrationV2/SET_CURRENT_COUNTRY';
export const SET_CURRENT_STATE = 'rregistrationV2/SET_CURRENT_STATE';
export const SET_NEXT_STEP = 'registrationV2/SET_NEXT_STEP';
export const SET_NEXT_ACTION = 'registrationV2/SET_NEXT_ACTION';
export const MOVE_TO_FIELD_STEP = 'registrationV2/MOVE_TO_FIELD_STEP';
export const SET_IS_DEPOSIT_LIMIT = 'registrationV2/SET_IS_DEPOSIT_LIMIT';
export const RESET_FORM = 'registrationV2/RESET_FORM';
export const SET_SHOW_LOADING_OVERLAY = 'registrationV2/SET_SHOW_LOADING_OVERLAY';
