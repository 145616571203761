import { getCookie } from 'bv';

import {
  START_REGISTRATION_FAILURE,
  START_REGISTRATION_SUCCESS,
  START_REGISTRATION_INIT,
  REFRESH_REGISTRATION_FAILURE,
  REFRESH_REGISTRATION_SUCCESS,
  REFRESH_REGISTRATION_INIT,
  FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS,
  CHANGE_STANDARD_FAILURE,
  CHANGE_STANDARD_INIT,
  CHANGE_STANDARD_SUCCESS,
  SET_CURRENT_STATE,
  SET_CURRENT_STEP,
  SET_CURRENT_COUNTRY,
  SET_NEXT_STEP,
  SET_NEXT_ACTION,
  SET_IS_DEPOSIT_LIMIT,
  MOVE_TO_FIELD_STEP,
  RESET_FORM,
  SET_SHOW_LOADING_OVERLAY,
} from './action_types';

export const initialState = {
  loading: true,
  countries: [],
  standard: {},
  phoneNumberCountries: [],
  productId: null,
  depositLimits: { options: [], defaultValue: null },
  registrationId: null,
  currentStep: undefined,
  showDepositLimits: false,
  currentCountry: null,
  currentState: null,
  countryIsoCode: null,
  loadedConfigs: false,
};

const indexStandardSteps = (steps) => (
  steps.map((step, idx) => ({ ...step, number: idx + 1 }))
);

const getFieldStep = (steps, searchField) => (
  steps.find((step) => step.fields.find((field) => field.name === searchField))
);

const getRegistrationConfig = ({
  countries, countryIsoCode, stateIsoCode, standard,
}) => {
  const currentCountry = countries.find((c) => (
    c.countryIsoCode === countryIsoCode),
  ) || countries[0];

  const currentState = stateIsoCode || null;
  const indexedSteps = indexStandardSteps(standard.steps);

  return {
    currentAction: 'registration',
    standard: { steps: indexedSteps },
    productId: getCookie('product_id') || null,

    currentCountry,
    currentState,
    currentStep: indexedSteps[0],
  };
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case START_REGISTRATION_INIT:
      return initialState;

    case START_REGISTRATION_FAILURE: {
      return {
        ...state,
        standard: null,
        loading: false,
      };
    }

    case START_REGISTRATION_SUCCESS: {
      const { response } = action;
      const config = getRegistrationConfig({ ...response });

      return {
        ...state,
        ...response,
        ...config,
        loading: false,
        loadedConfigs: true,
      };
    }

    case REFRESH_REGISTRATION_INIT:
      return {
        ...state,
        standard: null,
        registrationId: null,
        loading: true,
      };

    case REFRESH_REGISTRATION_FAILURE: {
      return {
        ...state,
        standard: null,
        loading: false,
      };
    }

    case REFRESH_REGISTRATION_SUCCESS: {
      const { countries } = state;
      const config = getRegistrationConfig({ ...action, countries });

      return {
        ...state,
        ...action,
        ...config,
        loading: false,
      };
    }

    case CHANGE_STANDARD_INIT:
      return {
        ...state,
        showLoadingOverlay: true,
      };

    case CHANGE_STANDARD_FAILURE: {
      return {
        ...state,
        showLoadingOverlay: false,
      };
    }

    case CHANGE_STANDARD_SUCCESS: {
      const { standard, standardChanged } = action;

      const updatedStandard = standardChanged ? {
        ...standard,
        steps: indexStandardSteps(standard.steps),
      } : state.standard;

      return {
        ...state,
        standard: updatedStandard,
        currentStep: updatedStandard.steps[0],
        showLoadingOverlay: false,
      };
    }

    case FETCH_DEPOSIT_LIMIT_OPTIONS_SUCCESS:
      return {
        ...state,
        depositLimits: action.options,
      };
    case SET_NEXT_STEP: {
      return {
        ...state,
        currentStep: action.nextStep,
        lastStepVisitedNumber: action.nextStep.number,
      };
    }

    case SET_NEXT_ACTION: {
      const allActions = ['registration', ...state.postRegistrationActions];
      const currentActionIndex = allActions.findIndex(
        (actionName) => (actionName === state.currentAction),
      );

      return {
        ...state,
        currentAction: allActions[currentActionIndex + 1] || state.currentAction,
        currentStep: undefined,
      };
    }

    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.step,
        lastStepVisitedNumber: action.step.number,
      };

    case SET_CURRENT_COUNTRY:
      return {
        ...state,
        currentCountry: action.country,
        countryIsoCode: action.country.countryIsoCode,
      };

    case SET_CURRENT_STATE:
      return {
        ...state,
        currentState: action.state,
      };

    case MOVE_TO_FIELD_STEP:
      return {
        ...state,
        currentStep: getFieldStep(state.standard.steps, action.field),
      };

    case SET_IS_DEPOSIT_LIMIT:
      return {
        ...state,
        showDepositLimits: action.showDepositLimits,
      };

    case RESET_FORM:
      return {
        ...state,
        currentStep: undefined,
        lastStepVisitedNumber: 1,
        showDepositLimits: false,
      };

    case SET_SHOW_LOADING_OVERLAY: {
      return {
        ...state,
        showLoadingOverlay: action.state,
      };
    }

    default:
      return state;
  }
};
