import { t } from 'bv-i18n';

const createValidator = ({ errorMessage, value, pattern }) => (input) => {
  const error = t(`${errorMessage}`, { value });
  if (typeof input === 'string') {
    const matchCount = (input.match(pattern) || []).length;
    return matchCount >= value ? undefined : error;
  }
  return error;
};

export const minDigits = ({ errorMessage, value }) => createValidator({
  errorMessage,
  value,
  pattern: /\d/g,
});

export const minLetters = ({ errorMessage, value }) => createValidator({
  errorMessage,
  value,
  pattern: /[a-zA-Z]/g,
});

export const minUpperCased = ({ errorMessage, value }) => createValidator({
  errorMessage,
  value,
  pattern: /[A-Z]/g,
});

export const minLowerCased = ({ errorMessage, value }) => createValidator({
  errorMessage,
  value,
  pattern: /[a-z]/g,
});

export const regex = ({ errorMessage, value }) => createValidator({
  errorMessage,
  value: 1,
  pattern: new RegExp(value),
});

export const minSymbols = ({ errorMessage, allowedSymbols, value }) => createValidator({
  errorMessage,
  value,
  pattern: new RegExp(allowedSymbols, 'g'),
});
