import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useForm } from 'react-final-form';
import { escapeInput } from 'RegistrationV2/helpers/text_utils';
import {
  InputMask,
  FieldHint,
  FieldLabel,
  FieldError,
} from 'RegistrationV2/common/components';

const BasicText = ({
  input,
  label,
  hint,
  meta: {
    touched, error, active, submitError, dirtySinceLastSubmit,
  },
  mask,
  tooltipTitle,
  tooltipText,
  validations,
  hasAsyncValidation,
  placeholder,
  imageGuide,
}) => {
  const form = useForm();
  const [isAutofill, setIsAutofill] = useState(false);
  const maxLength = validations.find((v) => v.type === 'maxLength')?.value;

  const handleChange = ({ target }) => {
    setIsAutofill(target.matches(':autofill') || target.matches(':-webkit-autofill'));
    input.onChange(escapeInput(target.value));
  };

  const onBlur = () => {
    input.onBlur(input.value);
    if (hasAsyncValidation) form.mutators.triggerValidation(input.name);
  };

  const showError = (!active && touched && error) || (!dirtySinceLastSubmit && submitError);

  const containerClassNames = classnames(
    'regv2-form-group', {
      'is-autofill': isAutofill,
    },
  );

  const inputFieldClassNames = classnames(
    'regv2-form-control', {
      error: showError,
      active,
      'with-value': input.value,
    },
  );

  return (
    <div className={containerClassNames}>
      <FieldLabel
        label={label}
        htmlFor={input.name}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      {mask ? (
        <InputMask
          input={input}
          inputFieldClassNames={inputFieldClassNames}
          mask={mask}
          maxLength={maxLength}
        />
      ) : (
        <input
          {...input}
          id={input.name}
          className={inputFieldClassNames}
          autoCapitalize="off"
          autoCorrect="off"
          placeholder={placeholder}
          onChange={handleChange}
          maxLength={maxLength}
          onBlur={onBlur}
        />
      )}
      <FieldHint active={!showError} hint={hint} />
      <FieldError showError={showError} error={error || submitError} />
      { imageGuide && active && (
        <img
          src={imageGuide}
          alt={`image-guide-${input.name}`}
          className="regv2-image-guide"
        />
      ) }
    </div>
  );
};

BasicText.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  validations: PropTypes.arrayOf(Object).isRequired,
  hint: PropTypes.string,
  mask: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
  hasAsyncValidation: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  imageGuide: PropTypes.string,
};

BasicText.defaultProps = {
  hint: null,
  mask: null,
  tooltipTitle: null,
  tooltipText: null,
  placeholder: null,
  imageGuide: null,
};

export default BasicText;
