import {
  useState, useEffect, useRef,
} from 'react';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FieldLabel } from 'RegistrationV2/common/components';
import ErrorList from './error_list';

const Password = ({
  input,
  label,
  tooltipText,
  tooltipTitle,
  meta: {
    error, active, touched, dirty, dirtySinceLastSubmit,
  },
  validatorResults,
  maxLength,
}) => {
  const form = useForm();
  const { submitErrors } = useFormState();
  const submitError = submitErrors && submitErrors[input.name];

  const [showPassword, setShowPassword] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current && (touched || dirty)) form.focus('password');
  }, [showPassword]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const onBlur = () => {
    // Required to allow a possible next button click to be handled, otherwise won't work
    setTimeout(input.onBlur, 100);
  };

  const eyeClick = () => setShowPassword(!showPassword);

  const renderShowValidatorFeedback = () => {
    if (!dirtySinceLastSubmit && submitError) {
      return <span className="regv2-form-error-msg">{submitError}</span>;
    }
    if (active || (!active && touched && validatorResults.some((v) => !v.valid))) {
      return <ErrorList active={active} errorList={validatorResults.filter((v) => v.type !== 'maxLength')} />;
    }
    return null;
  };

  const onChange = ({ target }) => input.onChange(target.value);

  const inputFieldClassNames = classnames(
    'regv2-form-control', {
      error: !active && ((error && touched) || (submitError && !dirtySinceLastSubmit)),
      active,
      'with-value': !!(input.value) && touched,
    },
  );

  return (
    <>
      <FieldLabel
        label={label}
        htmlFor={input.name}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      <div className="regv2-form-control__eye-catcher-wrapper">
        <input
          {...input}
          type={showPassword ? 'text' : input.type}
          name={input.name}
          id={input.name}
          className={inputFieldClassNames}
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="new-password"
          maxLength={maxLength}
          onBlur={onBlur}
          onChange={onChange}
        />
        <span
          className={`regv2-form-control__eye-catcher-icon ${showPassword ? 'active' : 'inactive'}`}
          onClick={eyeClick}
        />
      </div>
      {renderShowValidatorFeedback()}
    </>
  );
};

Password.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  validatorResults: PropTypes.arrayOf(Object).isRequired,
  focusInput: PropTypes.func.isRequired,
  isChangePassword: PropTypes.bool.isRequired,
  rules: PropTypes.instanceOf(Object).isRequired,
  maxLength: PropTypes.number.isRequired,
  tooltipTitle: PropTypes.string,
  tooltipText: PropTypes.string,
};

Password.defaultProps = {
  tooltipTitle: null,
  tooltipText: null,
};

export default Password;
