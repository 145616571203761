import { t } from 'bv-i18n';
import * as methods from './methods';

const escapeString = (string) => (string || '').replace(/([-/\\^$*+?.()|[\]{}])/g, '\\$1');
const defaultSymbolsRegex = "'\"`:;_.-,~!?¿¡*+/|=€£%^&#$@()<>{}[]";

export default (validations) => (input) => {
  const inputValue = input ? escapeString(input) : '';
  return validations.reduce(
    (accumulator, validation) => {
      const { errorMessage, value, type } = validation;

      if (type === 'minUpperCased') return accumulator;
      if (type === 'minLowerCased') {
        const minUpperCasedRule = validations.find((v) => v.type === 'minUpperCased');
        if (!minUpperCasedRule) return accumulator;

        const uppercased = minUpperCasedRule.value;
        const lowercased = value;
        const error = 'registration.field.password.case.errorMessage';
        return [
          ...accumulator,
          {
            type: 'case',
            text: t(error, { uppercased, lowercased }),
            valid: methods.case({
              errorMessage: error, lowercased, uppercased,
            })(inputValue) === undefined,
          },
        ];
      }

      let methodProps;

      switch (type) {
        case 'minsymbols':
          methodProps = {
            ...validation,
            allowedSymbols: `[${escapeString(validations.find((v) => v.type === 'allowedSymbols')?.value || defaultSymbolsRegex)}]`,
          };
          break;
        case 'minLength':
          methodProps = {
            ...validation,
            allowEmpty: false,
          };
          break;
        default:
          methodProps = validation;
      }

      return (type in methods ? [
        ...accumulator,
        {
          type,
          text: t(`${errorMessage}`, { value }),
          valid: methods[type](methodProps)(inputValue) === undefined,
        },
      ] : accumulator);
    }, [],
  );
};
