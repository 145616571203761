export default (oAuthParams) => {
  const { identity, identity_provider: identityProvider } = oAuthParams;

  if (!identity) return {};

  const {
    country_iso_code: countryIsoCode,
    ...rest
  } = identity;

  return {
    country: countryIsoCode,
    identity_provider: identityProvider,
    ...rest,
  };
};
