import { FormSpy } from 'react-final-form';
import PropTypes from 'prop-types';
import { Modal, Card, Icon } from 'bv-components';

import { t } from 'bv-i18n';

import { OneAuthConnect } from 'RegistrationV2/common/components';

import GeneralSteps from './steps/general_steps';
import LastStep from './steps/last_step';
import Steps from './progress_bar/steps';

const FormStepView = (({
  isLastStep, isFirstStep, handleChange, handleSubmit, goBackStep, setRedirecting,
  currentStep, identityProvider, oneAuthEnabled,
  errorMessage, onCloseErrorMessage,
}) => {
  const { rows, title } = currentStep;

  return (
    <div className="registration-path__form-step-container">
      <form className="account-form regpath" onSubmit={handleSubmit}>
        <FormSpy
          subscription={{ values: true, submitFailed: true, dirtySinceLastSubmit: true }}
          onChange={handleChange}
        />
        <Card>
          <div className="regv2-form-header">
            {currentStep.number > 1 && (
              <span
                className="regv2-form-header__step-back-link"
                onClick={() => goBackStep}
              />
            )}
            <span className="regv2-form-header__title">
              {t(`${title}`)}
            </span>
          </div>
          <Steps handleSubmit={handleSubmit} currentStep={currentStep} />
          {!isLastStep && (
          <GeneralSteps
            rows={rows}
            isFirstStep={isFirstStep}
            currentStep={currentStep}
          />
          )}
          {isLastStep && <LastStep rows={rows} />}
          <p className="regv2-form-ssl-info">
            {t('registration.messages.ssl_encryption')}
            <Icon id="ssl-encryption" />
          </p>
        </Card>
      </form>
      {isFirstStep && !identityProvider && oneAuthEnabled && (
      <OneAuthConnect setRedirecting={setRedirecting} />
      )}
      {errorMessage && (
      <Modal
        danger
        actions={[
          {
            label: t('close'),
            danger: true,
            onClick: onCloseErrorMessage,
          },
        ]}
      >
        <p>{errorMessage}</p>
      </Modal>
      )}
    </div>
  );
});

FormStepView.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  isFirstStep: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBackStep: PropTypes.func.isRequired,
  currentStep: PropTypes.instanceOf(Object).isRequired,
  identityProvider: PropTypes.instanceOf(Object),
  errorMessage: PropTypes.string,
  oneAuthEnabled: PropTypes.bool.isRequired,
  setRedirecting: PropTypes.func.isRequired,
  onCloseErrorMessage: PropTypes.func.isRequired,
};

FormStepView.defaultProps = {
  identityProvider: null,
  errorMessage: null,
};

export default FormStepView;
