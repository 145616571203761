import { useContext } from 'react';
import classnames from 'classnames';
import { FieldLabel, FieldHint, FieldError } from 'RegistrationV2/common/components';
import OptionsList from './options_list';
import DropDownContext from './dropdown_context';

const DropdownView = () => {
  const {
    meta, showOptions, name, label, tooltipTitle, tooltipText, hint, onListBlur,
  } = useContext(DropDownContext);

  const {
    touched, error, dirtySinceLastSubmit, submitError, active,
  } = meta;

  const containerClasses = classnames(
    'regv2-form-control allow-dropdown', {
      expanded: showOptions,
    },
  );

  const showError = (!active && touched && error) || (!dirtySinceLastSubmit && submitError);

  return (
    <div className="regv2-form-group__select-list">
      <FieldLabel
        label={label}
        htmlFor={`account_${name}`}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      <div className={containerClasses} onBlur={onListBlur}>
        <OptionsList />
      </div>
      <FieldHint active={!showError} hint={hint} />
      <FieldError showError={showError} error={error} />
    </div>
  );
};

export default DropdownView;
