import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { escapeInput, formatInputToMask } from 'RegistrationV2/helpers/text_utils';

const InputMask = ({
  input: { value, name },
  mask,
  maxLength,
  inputFieldClassNames,
}) => {
  const form = useForm();

  const [localValue, setLocalValue] = useState(value || '');

  const handleChange = ({ target }) => {
    const escapedInput = escapeInput(target.value);
    const rawInput = escapedInput.split('').filter((char) => !mask.includes(char) || char === 'X').join('');

    const formattedInput = formatInputToMask(rawInput, mask);
    setLocalValue(formattedInput);

    form.change(name, rawInput);
  };

  return (
    <input
      id={name}
      value={localValue}
      className={inputFieldClassNames}
      autoCapitalize="off"
      autoCorrect="off"
      placeholder={mask}
      onChange={handleChange}
      maxLength={maxLength}
    />
  );
};

InputMask.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  mask: PropTypes.string.isRequired,
  maxLength: PropTypes.string.isRequired,
  inputFieldClassNames: PropTypes.string.isRequired,
};

export default InputMask;
