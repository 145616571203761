import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classnames from 'classnames';
import { v as bvVar } from 'bv';
import passwordValidation from 'RegistrationV2/validations/password';
import PasswordField from './password_field';

const Password = ({ validations, ...rest }) => {
  const [validatorResults, setValidatorResults] = useState([]);
  const validationFunction = passwordValidation(validations.filter((v) => v.value > 0));

  const validator = (value) => {
    const validatedFields = validationFunction(value);
    setValidatorResults(validatedFields);
    return validatedFields.every((validatedField) => validatedField.valid)
      ? undefined
      : validatedFields;
  };

  const maxLength = validations.find(({ type }) => type === 'maxLength')?.value || 32;
  const pwdCard = bvVar('regPathCards').password;
  const useCard = pwdCard[bvVar('brandId')] !== undefined ? pwdCard[bvVar('brandId')] : pwdCard.default;
  const wrapperClass = classnames({
    'regv2-form-group': !useCard,
    'bvs-card': useCard,
  });

  return (
    <div className={wrapperClass}>
      <Field
        validate={validator}
        validatorResults={validatorResults}
        component={PasswordField}
        maxLength={maxLength}
        type="password"
        {...rest}
      />
    </div>
  );
};

Password.propTypes = {
  validations: PropTypes.arrayOf(Object),
};

Password.defaultProps = {
  validations: [],
};

export default Password;
