import PropTypes from 'prop-types';

import { reducer, refreshRegistration } from 'RegistrationV2/ducks';
import AppContainer from './components/app/app_container';

const { addReducers, addActionDispatchers } = window.reduxState;

addReducers({
  registrationV2: reducer,
});
addActionDispatchers('registration', { refreshRegistration });

const RegistrationApp = ({ kind }) => (
  <AppContainer formAction={kind} />
);

RegistrationApp.propTypes = {
  kind: PropTypes.string,
};

RegistrationApp.defaultProps = {
  kind: 'registration',
};

export default RegistrationApp;
