import { t } from 'bv-i18n';
import { modalBack } from 'bv-helpers/location';

const { showDangerMessage } = window.ModalHelper;

export default ({ errorType, message, onRetry }) => {
  const actions = [
    {
      label: t('registration.errors.buttons.close'),
      inverse: true,
      danger: true,
      close: true,
      onClick: modalBack,
    },
    ...(onRetry ? [{
      label: t('registration.errors.buttons.retry'),
      danger: true,
      close: true,
      onClick: onRetry,
    }] : []),
  ];

  return showDangerMessage({
    title: t(`registration.errors.${errorType}.title`),
    iconClosable: false,
    html: `<p>${message || t(`registration.errors.${errorType}.message`)}</p>`,
    className: 'regv2-modal',
    actions,
  });
};
