import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  FieldHint,
  FieldLabel,
  FieldError,
} from 'RegistrationV2/common/components';

const DateOfBirth = ({
  input, label, hint, meta: {
    touched, error, active, submitError, dirtySinceLastSubmit,
  }, tooltipTitle, tooltipText,
}) => {
  const showError = (!active && touched && error) || (!dirtySinceLastSubmit && submitError);

  const inputFieldClassNames = classnames(
    'regv2-form-control', {
      error: showError,
      active,
      'with-value': input.value,
    },
  );

  return (
    <div className="regv2-form-group">
      <FieldLabel
        label={label}
        htmlFor={input.name}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      <input
        {...input}
        id={input.name}
        type="date"
        placeholder={label}
        className={inputFieldClassNames}
      />
      <FieldHint active={!showError} hint={hint} />
      <FieldError showError={showError} error={error || submitError} />
    </div>
  );
};

DateOfBirth.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  hint: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

DateOfBirth.defaultProps = {
  hint: null,
  tooltipTitle: null,
  tooltipText: null,
};

export default DateOfBirth;
