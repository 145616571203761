import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import NationalityView from './nationality_view';

const NationalityContainer = (props) => {
  const { input, values, defaultValue } = props;

  const onNationalityChange = ({ value }) => input.onChange(value);

  useEffect(() => {
    if (!input.value && defaultValue) {
      input.onChange(defaultValue);
    }
  }, []);

  const nationalityOptions = useMemo(() => (
    values.map((value) => ({
      value,
      text: t(`registration.citizenshipIsoCode.${value}`),
    }))
  ), [values]);

  return (
    <NationalityView
      onNationalityChange={onNationalityChange}
      nationalityOptions={nationalityOptions}
      {...props}
    />
  );
};

NationalityContainer.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.arrayOf(String).isRequired,
  defaultValue: PropTypes.string,
};

NationalityContainer.defaultProps = {
  defaultValue: null,
};

export default NationalityContainer;
