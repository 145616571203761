import PropTypes from 'prop-types';
import { FlagListSelect } from 'RegistrationV2/common/components/index';
import {
  FieldHint,
  FieldLabel,
  FieldError,
} from 'RegistrationV2/common/components';

const CurrencyFieldView = ({
  input,
  label,
  hint,
  currencyOptions,
  meta: {
    touched, error, dirtySinceLastSubmit, submitError, active,
  },
  onCurrencyChange,
  tooltipTitle,
  tooltipText,
}) => {
  const showError = (!active && touched && error) || (!dirtySinceLastSubmit && submitError);
  const selectedCurrency = currencyOptions.find(
    (c) => c.value === input.value,
  ) || currencyOptions[0];

  return (
    <div className="regv2-form-group">
      <FieldLabel
        label={label}
        htmlFor={`account_${input.name}`}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      <FlagListSelect
        options={currencyOptions}
        selectedOption={selectedCurrency}
        onOptionChange={onCurrencyChange}
        inputName={input.name}
      />
      <FieldHint active={!showError} hint={hint} />
      <FieldError showError={showError} error={error} />
    </div>
  );
};

CurrencyFieldView.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  currencyOptions: PropTypes.arrayOf(Object).isRequired,
  onCurrencyChange: PropTypes.func.isRequired,
  hint: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

CurrencyFieldView.defaultProps = {
  hint: null,
  tooltipTitle: null,
  tooltipText: null,
};

export default CurrencyFieldView;
