import { t } from 'bv-i18n';

const invalidCharsRegex = /['"`:;.,_\-~!?¿¡*+/|\\=%^&#$@()<>{}[\]§£¥€¢ˆ«»‹›–—‘’“”¨±°•·˜©®´¸×÷]/g;

const countSpecialChars = (input) => {
  if (typeof input === 'string') {
    const matches = input.match(invalidCharsRegex);
    return matches ? matches.length : 0;
  } if (typeof input === 'number') {
    return 0;
  }
  return -1;
};

export default ({ errorMessage }) => (input) => {
  if (!input) return undefined;
  const specialCharsCount = countSpecialChars(input);
  return specialCharsCount >= 1 ? t(`${errorMessage}`) : undefined;
};
