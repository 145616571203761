import PropTypes from 'prop-types';

const FieldLabel = ({
  label, htmlFor, tooltipText, tooltipTitle,
}) => (
  <label className="regv2-form-control-label" htmlFor={htmlFor}>
    {label}
    {tooltipText && tooltipTitle && (
      <span className="regv2-form-control-tooltip">
        <span className="regv2-form-control-tooltip__wrapper">
          <span className="regv2-form-control-tooltip__title">
            {tooltipTitle}
          </span>
          <span className="regv2-form-control-tooltip__text">
            {tooltipText}
          </span>
        </span>
      </span>
    )}
  </label>
);

FieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

FieldLabel.defaultProps = {
  tooltipTitle: null,
  tooltipText: null,
};

export default FieldLabel;
