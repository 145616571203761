import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllSteps,
  setCurrentStep,
  getLastStepVisitedNumber,
} from 'RegistrationV2/ducks';
import { t } from 'bv-i18n';
import Step from './step';

const Steps = ({ handleSubmit, currentStep }) => {
  const dispatch = useDispatch();

  const lastStepVisitedNumber = useSelector(getLastStepVisitedNumber);
  const activeSteps = useSelector(getAllSteps);

  const goBackStep = (step) => dispatch(setCurrentStep(step));

  const advanceSteps = async (steps) => {
    await Array.from({ length: steps }).reduce(async (promise) => {
      await promise;
      return handleSubmit();
    }, Promise.resolve());
  };

  const stepClick = (nextStep) => {
    if (nextStep.number < lastStepVisitedNumber) {
      goBackStep(nextStep);
    } else if (nextStep.number !== currentStep.number) {
      advanceSteps(nextStep.number - currentStep.number);
    }
  };

  return (
    <div className="regv2-steps-indicator">
      <div className="regv2-steps-indicator__steps-info">
        <div className="regv2-steps-indicator__step-title">
          {t(`${currentStep.subtitle}`)}
        </div>
        <div className="regv2-steps-indicator__step-number">
          {currentStep.number}
          {' / '}
          {activeSteps.length}
        </div>
      </div>
      <div className="regv2-steps-indicator__progress">
        {
        activeSteps.map((step) => (
          <Step
            key={step.name}
            step={step}
            currentStep={currentStep}
            onStepClick={stepClick}
          />
        ))
      }
      </div>
    </div>
  );
};

Steps.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  currentStep: PropTypes.instanceOf(Object).isRequired,
};

export default Steps;
