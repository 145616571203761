import { locale } from 'bv-i18n';
import { getExampleNumber } from 'libphonenumber-js';
import exampleNumbers from 'libphonenumber-js/mobile/examples';

export const getCountryByCode = (countries, code) => countries.find((c) => (
  c.code.toLowerCase() === code.toLowerCase()
));

export const getExamplePhoneNumber = (countryCode) => {
  if (!countryCode) return null;
  const phoneNumber = getExampleNumber(countryCode.toUpperCase(), exampleNumbers);
  return phoneNumber ? phoneNumber.formatNational() : null;
};

export const getInitCountry = (inputValue, currentCountry, phoneCountries) => {
  // 1st match country to cached value
  if (inputValue && inputValue.country_iso) {
    return getCountryByCode(phoneCountries, inputValue.country_iso);
  }

  // 2nd match country to default country
  const defaultCountry = getCountryByCode(phoneCountries, currentCountry.countryIsoCode);
  if (defaultCountry) return defaultCountry;

  // 3rd by locale if available
  const shortLocale = locale().split('-')[1].toLowerCase();
  const country = getCountryByCode(phoneCountries, shortLocale);
  if (country) return country;

  return phoneCountries[0];
};
