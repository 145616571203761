export const SET_ONE_AUTH_PARAMS = 'REGISTRATION::SET_ONE_AUTH_PARAMS';
export const RESET_ONE_AUTH_PARAMS = 'REGISTRATION::RESET_ONE_AUTH_PARAMS';

export const setOneAuthParams = (params) => ({
  type: SET_ONE_AUTH_PARAMS,
  params,
});

export const resetOneAuthParams = () => ({
  type: RESET_ONE_AUTH_PARAMS,
});

export const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ONE_AUTH_PARAMS:
      return action.params;

    case RESET_ONE_AUTH_PARAMS: {
      return {};
    }
    default:
      return state;
  }
};
