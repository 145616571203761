import PropTypes from 'prop-types';

import RegistrationContainer from 'RegistrationV2/components/form_kinds/registration/registration_container';
import BonusSelectionContainer from 'RegistrationV2/components/form_kinds/bonus_selection/bonus_selection_container';
import CashierContainer from 'RegistrationV2/components/form_kinds/cashier/cashier_container';

const componentMap = {
  registration: RegistrationContainer,
  bonus_selection: BonusSelectionContainer,
  cashier: CashierContainer,
};

const FormKind = ({ action }) => {
  const Component = componentMap[action];

  return Component ? <Component /> : null;
};

FormKind.propTypes = {
  action: PropTypes.string.isRequired,
};

export default FormKind;
