import { useEffect } from 'react';
import { Spinner } from 'bv-components';
import { useHistory } from 'react-router-dom';

const CashierContainer = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/bv_cashier/deposit');
  }, []);

  return <Spinner />;
};

export default CashierContainer;
