import PropTypes from 'prop-types';

const SingleFlag = ({ selectedText, selectedCode, inputName }) => (
  <div className="regv2-form-control__with-flags separate-dial-code single-flag">
    <div className={`regv2-flag-container regv2-flag-container__${inputName}`}>
      <div className="selected-flag" title={`${selectedText}`}>
        <div className={`flag:${selectedCode.toUpperCase()}`} />
        {inputName === 'mobile' && (
          <div className="selected-dial-code">+</div>
        )}
        <span>
          {selectedText}
        </span>
      </div>
    </div>
  </div>
);

SingleFlag.propTypes = {
  selectedText: PropTypes.string.isRequired,
  selectedCode: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
};

export default SingleFlag;
