import { useEffect } from 'react';
import { isHCaptcha, isInvisibleHCaptcha, getCaptchaService } from 'bv-services';

const Hcaptcha = () => {
  useEffect(() => {
    if (isHCaptcha || isInvisibleHCaptcha) {
      getCaptchaService({ allowHCaptcha: true }).refresh();
    }
  }, []);

  return (isHCaptcha || isInvisibleHCaptcha) && <div id="hcaptcha" className="hcaptcha" />;
};

export default Hcaptcha;
