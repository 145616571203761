import { useContext } from 'react';
import classnames from 'classnames';
import DropDownContext from './dropdown_context';

const OptionsList = () => {
  const {
    dropUpList, showOptions, label, inputRef, listRef, selectedOption, filteredOptions,
    optionSearch, onOptionsSearch, onContainerMouseDown, onMouseDown,
  } = useContext(DropDownContext);

  const listClasses = classnames(
    'select-list', {
      hide: !showOptions,
      dropup: dropUpList,
    },
  );

  return (
    <div className="regv2-select-list" onMouseDown={onContainerMouseDown}>
      <div title={selectedOption?.description || label}>
        <input
          ref={inputRef}
          className="regv2-form-control__select-list"
          value={optionSearch === null ? (selectedOption?.description || label) : optionSearch}
          onChange={onOptionsSearch}
        />
      </div>
      <ul className={listClasses} ref={listRef}>
        {filteredOptions.map((option) => (
          <li
            key={option.value}
            className={`option ${option.value}`}
            onMouseDown={(evt) => onMouseDown(evt, option)}
          >
            {option.description && (
            <span className="">{option.description}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OptionsList;
