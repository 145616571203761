import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { debounce } from 'underscore';
import { findAddress, retrieveAddress } from 'RegistrationV2/api';
import { getRegistrationId, getCurrentStep } from 'RegistrationV2/ducks';
import AddressOptions from './address_options';
import AddressFinderInput from './adress_finder_input';

const AddressFinderContainer = (props) => {
  const registrationId = useSelector(getRegistrationId);
  const currentStep = useSelector(getCurrentStep);

  const form = useForm();

  const [addressOptions, setAddressOptions] = useState([]);
  const [hasAddressControlError, setHasAddressControlError] = useState(false);
  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const [searchText, setSearchText] = useState('');

  const getAddressFields = () => {
    const fields = currentStep.rows.flatMap((row) => [...row.fields]);
    return fields.filter((field) => field.name !== 'addressFinder');
  };

  const renderError = () => {
    setHasAddressControlError(true);
    setAddressOptions([]);
  };

  const searchAddress = (text) => {
    setHasAddressControlError(false);
    if (text) {
      findAddress({
        registrationId,
        search: text,
        optionId: null,
        limit: 10,
      }).then((response) => {
        if (response.error) renderError();
        else {
          setAddressOptions(response.options);
          setShowAddressOptions(true);
        }
      });
    }
  };
  const debouncedAddressFind = useCallback(debounce(searchAddress, 500), []);

  const onAddressOptionClick = (option) => {
    setHasAddressControlError(false);
    if (option.retrievable) {
      retrieveAddress({
        registrationId,
        id: option.id,
      }).then((result) => {
        setAddressOptions([]);
        setShowAddressOptions(false);
        getAddressFields().forEach(({ name: field }) => {
          form.change(field, result[field] || null);
        });
        setSearchText('');
      });
    } else {
      findAddress({
        registrationId,
        search: option.text,
        optionId: option.id,
        limit: 10,
      }).then((response) => {
        if (response.error) renderError();
        else setAddressOptions(response.options);
        setShowAddressOptions(true);
      });
    }
  };

  useEffect(() => {
    debouncedAddressFind(searchText);
  }, [searchText]);

  return (
    <>
      <AddressFinderInput
        hasAddressControlError={hasAddressControlError}
        onBlur={() => setShowAddressOptions(false)}
        onFocus={() => setShowAddressOptions(true)}
        onChange={(e) => setSearchText(e.target.value)}
        searchText={searchText}
        {...props}
      />

      {showAddressOptions && (
        <AddressOptions
          onAddressOptionClick={onAddressOptionClick}
          addressOptions={addressOptions}
        />
      )}
    </>
  );
};

export default AddressFinderContainer;
