import { useEffect } from 'react';
import { useForm, useFormState, Field } from 'react-final-form';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import PropTypes from 'prop-types';
import {
  FieldHint,
  FieldLabel,
  FieldError,
} from 'RegistrationV2/common/components';

const getClassName = (value, currentValue) => (
  classnames('regv2-form-chip', { active: value === currentValue })
);

const radioInput = (name, opt, selected, validate) => (
  <Field
    name={name}
    type="radio"
    value={opt}
    validate={validate}
  >
    {({ input }) => (
      <label key={opt} htmlFor={`account_${name}_${opt}`} className={getClassName(opt, selected)}>
        <input
          id={`account_${name}_${opt}`}
          type="radio"
          value={opt}
          className="regv2-form-radio"
          {...input}
        />
        {t(`registration.field.${name}.option.${opt}`)}
      </label>
    )}
  </Field>
);

const Enum = ({
  values: options,
  input: { name, value },
  validate,
  hint,
  label,
  default: defaultValue,
  tooltipText,
  tooltipTitle,
}) => {
  const form = useForm();
  const { touched, errors } = useFormState();

  useEffect(() => {
    if (defaultValue && !value) {
      form.change(name, defaultValue);
    }
  }, []);

  return (
    <div className="regv2-form-group">
      <FieldLabel
        label={label}
        htmlFor={`account_${name}`}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
      <div className="regv2-form-fields-wrapper">
        { options.map((opt) => radioInput(name, opt, value, validate)) }
      </div>
      <FieldHint active hint={hint} />
      <FieldError showError={touched[name]} error={errors[name]} />
    </div>
  );
};

Enum.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  validate: PropTypes.func.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  default: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

Enum.defaultProps = {
  hint: null,
  default: null,
  tooltipTitle: null,
  tooltipText: null,
};

export default Enum;
