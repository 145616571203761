import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import ErrorItem from './error_item';

const ErrorList = ({ errorList, active }) => (
  <ul className="regv2-form-error-list">
    <li className="success">{t('registration.field.password.errorslist.header')}</li>
    { errorList.map((err) => <ErrorItem {...err} key={err.type} active={active} />) }
  </ul>
);

ErrorList.propTypes = {
  errorList: PropTypes.arrayOf(Object).isRequired,
  active: PropTypes.bool.isRequired,
};

export default ErrorList;
