export const getOptionCode = (option) => option?.value?.toUpperCase();

export const getSelectedOptionCode = (
  selectedOption, showOptions, optionSearch, filteredOptions,
) => {
  if (optionSearch && showOptions) {
    if (!filteredOptions.length) return 'EMPTY';
    return filteredOptions.find((opt) => (
      (opt.text) === optionSearch
    ))?.value?.toUpperCase() || filteredOptions[0].value?.toUpperCase();
  }
  return selectedOption ? selectedOption.value?.toUpperCase() : 'EMPTY';
};
