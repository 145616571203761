import reactStringReplace from 'react-string-replace';
import { t } from 'bv-i18n';

const { CUSTOMER_CONSENT_URLS } = window.VCSTATE;

const bodyTextLinks = [{
  pattern: '(terms_conditions_link)',
  tag: 'terms_and_conditions',
  text: t('javascript.registration_path.terms_conditions.title'),
},
{
  pattern: '(privacy_policy_link)',
  tag: 'privacy_policy',
  text: t('javascript.registration_path.privacy_notice.title'),
}, {
  pattern: '(account_documentation_requirements_link)',
  tag: 'documents_we_may_require',
  text: t('javascript.registration_path.documents_we_may_require.title'),
}];

const errorMessageLinks = [{
  pattern: '(login_link)',
  link: '/login',
  text: t('javascript.sign_in_and_up_tabs.log_in'),
}];

// Emoji char codes
const emojis = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

export const escapeInput = (text) => text.replace(emojis, '');

export const renderBodyText = (text) => (
  bodyTextLinks.reduce((acc, value) => (
    reactStringReplace(acc, value.pattern, () => (
      <a className="bvs-text-secondary" href={CUSTOMER_CONSENT_URLS[value.tag]} target="_blank" rel="noreferrer">{value.text}</a>
    ))
  ), text)
);

export const renderErrorMessage = (text) => (
  errorMessageLinks.reduce((acc, value) => (
    reactStringReplace(acc, value.pattern, () => (
      <a
        className="bvs-text-secondary"
        href={value.link}
        rel="noreferrer"
      >
        {value.text}
      </a>
    ))
  ), text)
);

export const formatInputToMask = (rawInput, mask) => {
  let formattedInput = '';
  let rawInputIndex = 0;
  let maskIndex = 0;

  while (rawInputIndex < rawInput.length && maskIndex < mask.length) {
    if (mask[maskIndex] === 'X') {
      formattedInput += rawInput[rawInputIndex];
      rawInputIndex += 1;
    } else {
      formattedInput += mask[maskIndex];
    }
    maskIndex += 1;
  }

  return formattedInput;
};
