import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import {
  ModalWindow, SiteFooter, Button, LiveChat, Icon,
} from 'bv-components';
import PromotionsContainer from 'RegistrationV2/components/promotions_container';
import { t } from 'bv-i18n';

export default (tKey) => (WrappedComponent) => {
  const RegModal = (props) => {
    const { onClose } = props;
    useEffect(() => {
      document.body.classList.add('smartbanner--hide');

      return () => { document.body.classList.remove('smartbanner--hide'); };
    }, []);

    const HeaderContent = () => useMemo(() => (
      <>
        <div className="site-header__logo">
          <a className="bvs-site-logo" href="/">
            <span role="img" aria-label="Home" />
          </a>
        </div>
        <Button
          to="/login"
          className="buttons__login_bar--login"
          primary
        >
          {t('login_now')}
        </Button>
      </>
    ));

    // Post reg actions currenrly replicating reg v1
    if (tKey === 'account_created') {
      return (
        <ModalWindow
          title={t(`javascript.account_form.form_titles.${tKey}`)}
          className="registration-modal"
          renderHeader={() => <div className="register_logo" />}
          showBack={false}
          onClose={onClose}
        >
          <>
            <WrappedComponent {...props} />
            <SiteFooter
              className="modal_footer"
              tagName="div"
              target="_blank"
              isRegPath
            />
          </>
        </ModalWindow>
      );
    }

    return (
      <ModalWindow
        title={t(`javascript.account_form.form_titles.${tKey}`)}
        className="regv2-modal"
        renderHeader={() => HeaderContent()}
        showBack={false}
        showClose={false}
        onClose={onClose}
        confirmClose={{
          title: t('javascript.account_form.leave_registration'),
          btnNoInversed: false,
        }}
      >
        <>
          <div className="regv2__left-content">
            <WrappedComponent {...props} />
            <LiveChat
              className="live-chat live-chat--regpath"
              location="Registration"
              showButton={false}
              text={(
                <>
                  <Icon id="live-chat" />
                  {t('javascript.quick_chat.need_help')}
                </>
              )}
            />
            <SiteFooter
              className="modal_footer"
              tagName="div"
              target="_blank"
              isRegPath
            />
          </div>
          <div className="regv2__right-content">
            <PromotionsContainer />
          </div>
        </>
      </ModalWindow>
    );
  };

  RegModal.propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  return RegModal;
};
