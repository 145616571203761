import {
  useState, useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { ModalWindowContext } from 'bv-contexts';
import classnames from 'classnames';
import {
  shouldShowDropupList,
  initScrollableOffset,
  initBodyScroll,
} from 'RegistrationV2/helpers/dropdowns';
import SingleFlag from './single_flag';
import {
  getOptionCode,
  getSelectedOptionCode,
} from './helpers';

const FlagListSelectContainer = ({
  options, selectedOption, onOptionChange, inputName, placeholder,
}) => {
  const { scrollableRef } = useContext(ModalWindowContext);
  const inputRef = useRef();
  const listRef = useRef();

  const [showOptions, setShowOptions] = useState(false);
  const [optionSearch, setOptionSearch] = useState('');
  const [dropUpCountryList, setDropUpCountryList] = useState(false);
  const [scrollableOffset, setScrollableOffset] = useState(0);

  const filteredOptions = optionSearch
    ? options.filter((option) => (
      option.fullText || option.text).toLowerCase().includes(optionSearch.toLowerCase()),
    ) : options;

  const containerClasses = classnames(
    'regv2-form-control__with-flags allow-dropdown separate-dial-code', {
      expanded: showOptions,
    },
  );

  const countryListClasses = classnames(
    'country-list', {
      hide: !showOptions,
      dropup: dropUpCountryList,
    },
  );

  const onListClick = () => setShowOptions(!showOptions);
  const onListBlur = () => setShowOptions(false);

  const onOptionsSearch = ({ target }) => {
    setOptionSearch(target.value);
    setShowOptions(true);
  };

  const onOptionsSearchBlur = () => {
    setOptionSearch('');
    setShowOptions(false);
  };

  const onCountryMouseDown = (event, country) => {
    event.preventDefault();
    setOptionSearch('');
    onOptionChange(country);
  };

  const onFlagContainerMouseDown = (event) => {
    event.preventDefault();
    if (!showOptions) {
      inputRef.current.select();
    }
    onListClick();
  };

  useEffect(() => {
    initScrollableOffset({ scrollableRef, setScrollableOffset });
  }, [scrollableRef]);

  useEffect(() => {
    setDropUpCountryList(shouldShowDropupList({
      showOptions, scrollableRef, inputRef, scrollableOffset,
    }));

    if (showOptions) {
      const element = listRef.current.querySelector(`.country.${getOptionCode(selectedOption)}`);
      if (element) listRef.current.scrollTop = element.offsetTop;
    }
  }, [showOptions, selectedOption, scrollableRef, scrollableOffset]);

  useEffect(() => {
    initBodyScroll(listRef);
  }, []);

  if (options.length === 1) {
    return (
      <SingleFlag
        selectedText={selectedOption?.text || placeholder}
        selectedCode={selectedOption?.value}
        inputName={inputName}
      />
    );
  }

  return (
    <div className={containerClasses} onBlur={onListBlur}>
      <div className={`regv2-flag-container regv2-flag-container__${inputName}`} onMouseDown={onFlagContainerMouseDown}>
        <div className="selected-flag" title={`${selectedOption?.text || placeholder}`}>
          {selectedOption && (
            <div className={`flag:${getSelectedOptionCode(selectedOption, showOptions, optionSearch, filteredOptions)}`} />
          )}
          {inputName === 'mobile' && (
            <div className="selected-dial-code">+</div>
          )}
          <input
            ref={inputRef}
            className={`regv2-form-control__country regv2-form-control__country-${inputName}`}
            value={showOptions ? optionSearch : (selectedOption?.text || placeholder)}
            onChange={onOptionsSearch}
            onBlur={onOptionsSearchBlur}
          />
        </div>
        <ul className={countryListClasses} ref={listRef}>
          {placeholder && !optionSearch && (
            <li
              key=""
              className="country"
              onMouseDown={(evt) => onCountryMouseDown(evt, { value: null })}
            >
              <span className="country-name">{placeholder}</span>
            </li>
          )}
          {filteredOptions.map((option) => (
            <li
              key={option.value}
              className={`country ${getOptionCode(option)}`}
              onMouseDown={(evt) => onCountryMouseDown(evt, option)}
            >
              <div className="flag-box">
                <div className={`flag:${getOptionCode(option)}`} />
              </div>
              {inputName === 'mobile' && (
                <span className="dial-code">
                  +
                </span>
              )}
              {option.text && (
                <span className="country-name">{option.fullText || option.text}</span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FlagListSelectContainer.propTypes = {
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  selectedOption: PropTypes.instanceOf(Object).isRequired,
  onOptionChange: PropTypes.func.isRequired,
  inputName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

FlagListSelectContainer.defaultProps = {
  placeholder: null,
};

export default FlagListSelectContainer;
