import { setLocation, replaceFragment } from 'bv-helpers/location';
import { mainContextPath } from 'bv-services/route';
import { session as sessionActionDispatchers } from 'redux-action-dispatchers';
import * as dataLayer from '../services/data_layer';

const { VC } = window;
const { refreshLoggedIn } = sessionActionDispatchers;

export default (response) => {
  refreshLoggedIn();
  localStorage.setItem('run_login_kyc_strategies', 'true');
  dataLayer.pushLoginEvent();

  if (response.should_update_password) {
    replaceFragment('account/password_change');
  } else {
    let redirectTo = response.redirect || VC.afterLoginRedirect || mainContextPath() || '/';
    if (/^(?:[a-z+]+:)?\/\//.test(redirectTo)) {
      const url = new URL(redirectTo, document.baseURI);
      redirectTo = `${url.pathname}${url.search}`;
      setLocation(redirectTo);
    }
    replaceFragment(redirectTo);
  }
};
