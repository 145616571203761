import { t } from 'bv-i18n';

export default ({ errorMessage, value, allowEmpty = true }) => (input) => {
  const error = t(`${errorMessage}`, { value });
  if (!input) return allowEmpty ? undefined : error;
  if (typeof input === 'string' || typeof input === 'number') {
    const stringValue = String(input);
    return stringValue.length >= value ? undefined : error;
  }
  return error;
};
