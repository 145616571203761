import { Icon } from 'bv-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const ErrorItem = ({ valid, text, active }) => {
  const classNames = classnames({
    success: valid,
    error: !valid,
    active,
  });

  const icon = valid ? 'check' : 'danger';

  return (
    <li className={classNames}>
      <Icon id={icon} />
      {text}
    </li>
  );
};

ErrorItem.propTypes = {
  valid: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default ErrorItem;
