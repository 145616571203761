import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';

import { useOneAuth } from 'bv-hooks';

import { getCountries, setCurrentCountry } from 'RegistrationV2/ducks';
import getOauthFormValues from 'RegistrationV2/helpers/get_oauth_form_values';
import { scrollToFirstError } from 'RegistrationV2/helpers/form';
import { setFormValues } from 'RegistrationV2/local_storage';

import FormStepView from './form_step_view';

const FormStepContainer = (({
  isLastStep, isFirstStep, handleSubmit, setCurrentStep, activeSteps,
  resetOneAuthParams, currentStep,
}) => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [redirecting, setRedirecting] = useState(false);

  const countries = useSelector(getCountries);
  const oneAuthParams = useSelector((state) => state.registrationOneAuthParams || {});

  const dispatch = useDispatch();
  const form = useForm();
  const formState = useFormState();
  const [oneAuthEnabled] = useOneAuth();

  const { values, error, errors } = formState;
  const { identity_provider: identityProvider } = values;

  const goBackStep = () => dispatch(setCurrentStep(
    activeSteps.find((step) => (step.number === currentStep.number - 1)),
  ));
  const onCloseErrorMessage = () => setErrorMessage(false);

  const handleChange = (formSpy) => {
    const { values: spyValues, submitFailed, dirtySinceLastSubmit } = formSpy;
    setFormValues(spyValues, formState);
    if (submitFailed && !dirtySinceLastSubmit) {
      scrollToFirstError(errors);
    }
  };

  useEffect(() => {
    if (error) setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    const oauthValues = getOauthFormValues(oneAuthParams, countries);
    if (Object.keys(oauthValues).length) {
      Object.entries(oauthValues)
        .forEach(([field, value]) => {
          form.change(field, value);
          if (field === 'countryIsoCode') dispatch(setCurrentCountry(value));
        });
      resetOneAuthParams();
    }
  }, [countries, oneAuthParams]);

  if (redirecting) return <Spinner />;
  return (
    <FormStepView
      isLastStep={isLastStep}
      isFirstStep={isFirstStep}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      goBackStep={goBackStep}
      currentStep={currentStep}
      oneAuthEnabled={oneAuthEnabled}
      identityProvider={identityProvider}
      setRedirecting={setRedirecting}
      errorMessage={errorMessage}
      onCloseErrorMessage={onCloseErrorMessage}
    />
  );
});

FormStepContainer.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  isFirstStep: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetOneAuthParams: PropTypes.func.isRequired,
  currentStep: PropTypes.instanceOf(Object).isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  activeSteps: PropTypes.instanceOf(Array).isRequired,
};

export default FormStepContainer;
