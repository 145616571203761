import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pick } from 'underscore';

import { dataLayer } from 'bv-helpers';
import { getCaptchaService } from 'bv-services';
import { modalBack } from 'bv-helpers/location';

import {
  getAllSteps,
  getAllFields,
  getRegistrationId,
  getCurrentStep,
  getShowLoadingOverlay,
  setNextStep,
  setNextAction,
  resetForm,
  setShowLoadingOverlay,
} from 'RegistrationV2/ducks';
import submitForm from 'RegistrationV2/services/submit_form';
import { getFormValues } from 'RegistrationV2/local_storage';
import RegistrationView from './registration_view';

const RegistrationContainer = () => {
  const initialValues = getFormValues();
  const dispatch = useDispatch();

  const currentStep = useSelector(getCurrentStep);
  const activeSteps = useSelector(getAllSteps);
  const allFields = useSelector(getAllFields);
  const registrationId = useSelector(getRegistrationId);
  const showLoadingOverlay = useSelector(getShowLoadingOverlay);

  const isLastStep = activeSteps.length === currentStep.number;

  const submitFormHandler = async (values) => {
    const newValues = pick(values, allFields);

    newValues.token = await getCaptchaService({ allowHCaptcha: true }).execute('registration');

    return submitForm({
      registrationId,
      values: newValues,
      setNextAction: () => dispatch(setNextAction()),
      setShowLoadingOverlay: (state) => dispatch(setShowLoadingOverlay(state)),
    });
  };

  const onSubmit = async (values, form) => {
    if (isLastStep) return submitFormHandler(values, form);
    return dispatch(setNextStep(
      activeSteps.find(({ number }) => number === (currentStep.number + 1)),
    ));
  };

  useEffect(() => () => {
    dispatch(resetForm());
  }, []);

  useEffect(() => {
    if (currentStep?.number) {
      dataLayer.push({
        event: 'evt_reg_step_active',
        reg_step: currentStep.number,
      });
    }
  }, [currentStep]);

  return (
    <RegistrationView
      isLastStep={isLastStep}
      onSubmit={onSubmit}
      currentStep={currentStep}
      initialValues={initialValues}
      activeSteps={activeSteps}
      showLoadingOverlay={showLoadingOverlay}
      onClose={modalBack}
    />
  );
};

export default RegistrationContainer;
