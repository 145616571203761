import { Field } from 'react-final-form';
import { t } from 'bv-i18n';
import ConditionBox from './condition_box';

const TermsPrivacyPolicy = (props) => (
  <Field
    {...props}
    component={ConditionBox}
    name="termsPrivacyPolicy"
    validate={(value) => (value ? undefined : t('registration.field.termsPrivacyPolicy.notNull.errorMessage'))}
  />

);

export default TermsPrivacyPolicy;
