import { t } from 'bv-i18n';

const isNonEmpty = (value) => {
  if (typeof value === 'string') {
    return value.trim().length >= 1;
  } if (typeof value === 'number') {
    return true;
  }
  return false;
};

export default ({ errorMessage }) => (value) => (isNonEmpty(value) ? undefined : t(`${errorMessage}`));
