import { t } from 'bv-i18n';
import { postJSON } from 'bv-fetch';
import { renderErrorMessage } from 'RegistrationV2/helpers/text_utils';
import { handleApiError } from 'RegistrationV2/helpers/errors';
import { getValidatedFields, setValidatedFields } from 'RegistrationV2/local_storage';

export default async ({
  field, currentValue, registrationId,
}) => {
  const validatedFields = getValidatedFields(registrationId);
  if (validatedFields[field] && currentValue in validatedFields[field]) {
    return Promise.resolve(validatedFields[field][currentValue]);
  }

  return new Promise((resolve) => {
    postJSON('/api/registration/validate/field', {
      field,
      registrationId,
      value: currentValue,
    }).then((result) => {
      if (!result.error) {
        setValidatedFields(registrationId, { field, value: currentValue, result: null });
        resolve(undefined);
      } else if (result.errorCode === 'AC-148') {
        handleApiError({ ...result, type: 'validate' });
        resolve(undefined);
      } else {
        const errorResult = renderErrorMessage(t(`${result.message}`));
        setValidatedFields(registrationId, { field, value: currentValue, result: errorResult });
        resolve(errorResult);
      }
    });
  });
};
