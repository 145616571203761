import PropTypes from 'prop-types';

const FieldError = ({ showError, error }) => (
  showError && error && <span className="regv2-form-error-msg">{error}</span>
);

FieldError.propTypes = {
  showError: PropTypes.bool,
  error: PropTypes.string,
};

FieldError.defaultProps = {
  showError: false,
  error: null,
};

export default FieldError;
