import { t } from 'bv-i18n';

const uppercasedRegex = /[A-Z]/g;
const lowercasedRegex = /[a-z]/g;

export default ({ errorMessage, uppercased, lowercased }) => (input) => {
  const error = t(errorMessage, { uppercased, lowercased });

  if (typeof input === 'string') {
    if ((input.match(uppercasedRegex) || []).length < uppercased) return error;
    if ((input.match(lowercasedRegex) || []).length < lowercased) return error;
    return undefined;
  }
  return error;
};
