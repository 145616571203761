import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { accountData } from 'bv';
import withRegModal from 'RegistrationV2/hocs/with_reg_modal';

import withLazyLoad from 'bv-lazy-load';
import { Spinner } from 'bv-components';

const Offers = withLazyLoad(<Spinner />)('offers');

const BonusSelectionView = ({
  currentAction, optInHandler, setShowBack,
}) => {
  const history = useHistory();
  const [account, setAccount] = useState(null);

  useEffect(() => {
    history.replace('/account/offers?first_modal=true');
    accountData().then(setAccount);
  }, [currentAction]);

  if (!account) return <Spinner />;

  const config = {
    userName: account.first_name || '',
    showBackButton: false,
    optInHandler,
    // We have to do this due to offers having their own router
    // And better not to touch offers for now
    // Once we have only 1 router it should be less hacky
    // Probably the signup offers component should be the one with the ModalWindow
    // Instead of being created here
    offersList: () => { setShowBack(false); },
    offerDetail: () => { setShowBack(true); },
  };

  return (
    <Offers id="signup" config={config} />
  );
};

BonusSelectionView.propTypes = {
  currentAction: PropTypes.string.isRequired,
  optInHandler: PropTypes.func.isRequired,
  setShowBack: PropTypes.func,
};

BonusSelectionView.defaultProps = {
  setShowBack: () => {},
};

export default withRegModal('account_created')(BonusSelectionView);
