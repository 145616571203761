export const SET_HCAPTCHA_COMPLETED = 'captcha/SET_HCAPTCHA_COMPLETED';

export const setHCaptchaCompleted = (completed) => ({
  type: SET_HCAPTCHA_COMPLETED,
  payload: { completed },
});

const initialState = {
  hCaptchaCompleted: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_HCAPTCHA_COMPLETED:
      return {
        hCaptchaCompleted: action.payload.completed,
      };

    default:
      return state;
  }
};
