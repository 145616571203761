import * as api from 'RegistrationV2/api';
import { dataLayer } from 'bv-helpers';
import { isHCaptcha, getCaptchaService } from 'bv-services';
import { session as sessionActionDispatchers } from 'redux-action-dispatchers';
import { accountNumber } from 'bv-helpers/session';
import { set } from 'bv-local-storage';
import { removeFormValues } from 'RegistrationV2/local_storage';
import { handleApiError } from 'RegistrationV2/helpers/errors';

const { refreshLoggedIn } = sessionActionDispatchers;

const captchaService = getCaptchaService({ allowHCaptcha: true });

const refreshHCaptcha = () => {
  if (isHCaptcha) {
    captchaService.refresh();
  }
};

const submitForm = (props) => {
  const {
    registrationId,
    values,
    setNextAction,
    setShowLoadingOverlay,
    hasRetried = false,
  } = props;

  const onRetrySubmit = () => submitForm(props);

  setShowLoadingOverlay(true);

  const { mobile } = values;
  const formedMobileNumber = mobile?.number ? `+${mobile?.dial_code}${mobile?.number}` : null;

  return api.submitForm(registrationId, {
    ...values,
    mobile: formedMobileNumber,
  })
    .then(async (response) => {
      if (!response.error) {
        if (response.needLocationCheck) {
          set('need_location_check', 1);
        }

        dataLayer.push({
          event: 'evt_registration',
          account_number: accountNumber(),
        });

        setNextAction();
        refreshLoggedIn();
        removeFormValues();
        setShowLoadingOverlay(false);
      } else if (response.retry) {
        if (!hasRetried && captchaService.shouldRetry()) {
          submitForm(
            registrationId,
            { ...values, token: await captchaService.refresh() },
            setNextAction,
            setShowLoadingOverlay,
            true);
        } else {
          handleApiError({ ...response, onRetry: onRetrySubmit, type: 'submit' });
        }
        refreshHCaptcha();
        setShowLoadingOverlay(false);
      } else {
        refreshHCaptcha();
        setShowLoadingOverlay(false);
        handleApiError({ ...response, onRetry: onRetrySubmit, type: 'submit' });
      }
    })
    .catch(() => {
      refreshHCaptcha();
      setShowLoadingOverlay(false);
      handleApiError({ onRetry: onRetrySubmit, type: 'submit' });
    });
};

export default submitForm;
