import { t } from 'bv-i18n';

const countLatinChars = (input) => {
  if (typeof input === 'string') {
    const matches = input.match(/[A-z0-9\u00C0-\u00ff]+/g);
    return matches ? matches.length : 0;
  } if (typeof input === 'number') {
    return 0;
  }
  return -1;
};

export default ({ errorMessage }) => (input) => {
  if (!input) return undefined;
  const latinCharsCount = countLatinChars(input);
  return latinCharsCount >= 1 ? t(`${errorMessage}`) : undefined;
};
